import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const CreatePassword = lazy(() => import('presentation/pages/auth/createPassword'))

export const AuthRoutes = [
  {
    nameUrl: 'Primer Cambio de Contraseña',
    path: urlRoutes.public.auth.url,
    element: CreatePassword,
    rols: ['*'],
  },
]
