import { Box, Card, CardContent, IconButton, useTheme } from '@mui/material'
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material'

import { TextBold, TextParagraph } from 'presentation/components/elements'

type ProductDetailViewCardProps = {
  cardContent: any
  handleCloseCarousel: () => void
  handleNext: () => void
  handlePrev: () => void
  onlyOne?: boolean
  isFirstElement?: boolean
  isLastElement?: boolean
}

function ProductDetailViewCard({
  cardContent,
  handleCloseCarousel,
  handleNext,
  handlePrev,
  onlyOne,
  isFirstElement,
  isLastElement,
}: ProductDetailViewCardProps) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        display: 'flex',
        maxWidth: '998px',
        width: '100%',
        height: '500px',
        borderRadius: '30px',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX="1em"
        sx={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${cardContent?.imageUrl})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '70%',
          height: '100%',
        }}
      >
        <IconButton
          onClick={handlePrev}
          sx={{
            backgroundColor: 'white',
            paddingLeft: '10px',
            display: `${onlyOne ? 'none' : 'flex'}`,
          }}
          disabled={isFirstElement}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{ backgroundColor: 'white', display: `${onlyOne ? 'none' : 'flex'}` }}
          disabled={isLastElement}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <CardContent sx={{ maxWidth: '400px', width: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="1em"
        >
          <IconButton onClick={handleCloseCarousel} sx={{ alignSelf: 'flex-end' }}>
            <HighlightOffIcon fontSize="large" />
          </IconButton>
          <Box sx={{ width: '100%', margin: '20px 0 16px 0' }}>
            <TextBold fontSize="34px" lineHeight="39.84px" color={theme.mixins.outerSpace.color}>
              {cardContent?.title}
            </TextBold>
          </Box>
          <TextParagraph fontSize="14px" lineHeight="20px">
            {cardContent?.detailViewText}
          </TextParagraph>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ProductDetailViewCard
