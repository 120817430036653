import { Details } from 'domain/entities/promotion'

export const promotionEmpty = {
  additionalSavingsTotal: 0,
  configuredGoals: false,
  estimatedNumberStudentsEvent: 0,
  estimatedNumberStudentsTravel: 0,
  individualEventGoal: 0,
  individualTotalGoal: 0,
  individualTravelGoal: 0,
  movementsTotal: 0,
  percentageSavings: 0,
  performance: 0,
  realAmountOfRegisteredStudents: 0,
  realSavings: 0,
  savingsEndDate: '',
  totalEstimatedGoal: 0,
  showAdditionalSavings: false,
  showMovements: false,
}

export type PromotionState = {
  error: string | null
  isLoading: boolean
  promotion: Details
}
