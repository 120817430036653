import { createContext } from 'react'

import { Student } from 'domain/entities/representative'

interface ILayoutContext {
  selectedItemMenu: Student | null
  setSelectedItemMenu: any
}

const defaultState = {
  selectedItemMenu: null,
  setSelectedItemMenu: null,
}

const LayoutContext = createContext<ILayoutContext>(defaultState)

export default LayoutContext
