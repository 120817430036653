import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlaceToPayState } from 'domain/models/placeToPay'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: PlaceToPayState = {
  error: null,
  isLoading: false,
  isLoadingChangePaymentMethod: false,
}
export const placeToPaySlice = createSlice({
  name: 'placeToPay',
  initialState,
  reducers: {
    createSessionStart: (state: PlaceToPayState) => {
      state.isLoading = true
      state.isLoadingChangePaymentMethod = false
      state.error = null
    },
    createSessionSuccess: (state: PlaceToPayState) => {
      state.isLoading = false
    },
    createSessionError: (state: PlaceToPayState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    changePaymentMethodStart: (state: PlaceToPayState) => {
      state.isLoadingChangePaymentMethod = true
      state.error = null
    },
    changePaymentMethodSuccess: (state: PlaceToPayState) => {
      state.isLoadingChangePaymentMethod = false
    },
    changePaymentMethodError: (state: PlaceToPayState, action: PayloadAction<string>) => {
      state.isLoadingChangePaymentMethod = false
      state.error = action.payload
    },
  },
})

export const placeToPayState = (state: RootState) => state.placeToPay
export const {
  createSessionStart,
  createSessionSuccess,
  createSessionError,
  changePaymentMethodSuccess,
  changePaymentMethodStart,
  changePaymentMethodError,
} = placeToPaySlice.actions

export default placeToPaySlice.reducer
