import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CarouselSate, PayloadCarouselOpen } from 'domain/models/carousel'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: CarouselSate = {
  isCarouselOpen: false,
  startPosition: 0,
  elements: [],
}

const carouselSlice = createSlice({
  name: 'carousel',
  initialState,
  reducers: {
    carouselOpen: (state: CarouselSate, action: PayloadAction<PayloadCarouselOpen>) => {
      state.isCarouselOpen = true
      state.startPosition = action.payload.startPosition
      state.elements = action.payload.elements
    },
    carouselClose: (state: CarouselSate) => {
      state.isCarouselOpen = false
    },
  },
})

export const { carouselOpen, carouselClose } = carouselSlice.actions

export const carouselState = (state: RootState) => state.carousel
export default carouselSlice.reducer
