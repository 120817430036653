import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import {
  fundAndSavingsInformationText,
  simulatorText,
  travelVoteText,
} from 'presentation/constant/texts/sidebar'

export const sideBarElements = [
  {
    id: 1,
    route: urlRoutes.private.simulator.urlTo,
    expandedName: 'panel1',
    ariaControls: 'panel1d-content',
    panelId: 'panel1d-header',
    name: simulatorText,
  },
  {
    id: 2,
    route: urlRoutes.private.generalBackgroundHistory.urlTo,
    expandedName: 'panel2',
    ariaControls: 'panel2d-content',
    panelId: 'panel2d-header',
    name: fundAndSavingsInformationText,
  },
  {
    id: 3,
    route: urlRoutes.private.travelVote.urlTo,
    expandedName: 'panel3',
    ariaControls: 'panel3d-content',
    panelId: 'panel2d-header',
    name: travelVoteText,
  },
]
