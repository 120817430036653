import { MouseEventHandler } from 'react'
import { Box, MenuItem } from '@mui/material'

import { ButtonApp } from 'presentation/components/elements'
import { getIntoText } from 'presentation/constant/globals'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { committeeText, representativeText } from 'presentation/constant/texts/header'
import { StyledDivider, StyledLink, StyledMenu, StyledMenuItem } from './index.styled'

type HeaderSignInItemProps = {
  isOpenSignInMenu: boolean
  handleCloseSignInMenu: () => void
  anchorSignInEl: any
  handleClickListSignInItemMenu: MouseEventHandler<HTMLElement>
}

export default function HeaderSignInItem({
  isOpenSignInMenu,
  handleCloseSignInMenu,
  anchorSignInEl,
  handleClickListSignInItemMenu,
}: HeaderSignInItemProps) {
  return (
    <ButtonApp onClick={handleClickListSignInItemMenu}>
      {getIntoText}
      <StyledMenu anchorEl={anchorSignInEl} open={isOpenSignInMenu} onClose={handleCloseSignInMenu}>
        <Box
          sx={{
            maxWidth: '150px',
            width: '100%',
            padding: '2px 14px',
          }}
        >
          <StyledLink to={urlRoutes.public.auth.login.urlTo}>
            <MenuItem>{representativeText.toUpperCase()}</MenuItem>
          </StyledLink>
          <Box sx={{ margin: '3px 0' }}>
            <StyledDivider />
          </Box>
          <StyledMenuItem
            onClick={() => window.open(`${process.env.REACT_APP_URL_LOGIN_BACKOFFICE}`)}
          >
            {committeeText.toUpperCase()}
          </StyledMenuItem>
        </Box>
      </StyledMenu>
    </ButtonApp>
  )
}
