import { ChangeEvent, useState } from 'react'
import dayjs from 'dayjs'
import { Box, Card, ClickAwayListener, useMediaQuery, useTheme } from '@mui/material'
import {
  AttachMoney as AttachMoneyIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateRange } from 'react-date-range'
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { TextParagraph, TextTitle } from 'presentation/components/elements'
import { InputApp, StyledTooltip } from 'presentation/styles/index.styled'
import { convertStringPrice, validateDecimal } from 'presentation/utils/format'
import { FormControlApp } from 'presentation/components/form/input'
import {
  bottomDisclaimer,
  calculateValueMonthlySavingsText,
  estimatedMonthlyText,
  numberMonthsText,
  savingsDeadlineText,
  savingsStartlineText,
  simulatorText,
  targetAmountText,
  tooltipTexts,
} from 'presentation/constant/texts/simulator'
import { ceroText } from 'presentation/constant/placeholders/simulator'
import { labels } from 'presentation/constant/labels'
import { ContainerBoxFormControl, StyledBox, StyledDataPicker, StyledInput } from './index.styled'

export default function Simulator() {
  const theme = useTheme()
  const isScreenLowerThanLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [targetAmount, setTargetAmount] = useState<any>(0)
  const [isShowErrorAmount, setIsShowErrorAmount] = useState<boolean>(false)
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ])

  const handleToggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen)
  }

  const handleChangeTargetAmount = (event: ChangeEvent<HTMLInputElement>) => {
    setTargetAmount(event.target.value)
    if (event.target.value === '') {
      setIsShowErrorAmount(true)
    }
    if (validateDecimal(event.target.value)) {
      setIsShowErrorAmount(false)
    } else {
      setIsShowErrorAmount(true)
    }
  }
  const handleChangeDateRange = (item: any) => {
    setDateRange([item.selection])
  }

  const handleCloseCalendar = () => {
    setIsCalendarOpen(false)
  }

  const getNumberMonthsBetweenYears = (
    endDateYear: any,
    startDateYear: any,
    endDateMonth: any,
    startDateMoth: any,
  ) => {
    const decemberMonth = 11
    let numberMonthsBetweenYears
    const numberYearsDifference = endDateYear - startDateYear
    if (numberYearsDifference > 1) {
      if (endDateMonth >= startDateMoth) {
        numberMonthsBetweenYears = numberYearsDifference * 12 + endDateMonth - startDateMoth
      } else {
        numberMonthsBetweenYears = numberYearsDifference * 12 - startDateMoth - endDateMonth - 1
      }
    } else {
      numberMonthsBetweenYears = decemberMonth - startDateMoth + endDateMonth
    }
    return numberMonthsBetweenYears
  }

  const numberMonths = () => {
    const startDateDay = Number(dayjs(dateRange[0].startDate).format('D'))
    const endDateDay = Number(dayjs(dateRange[0].endDate).format('D'))
    /* aqui evaluo que la fecha final es mas de 1 mes que la inicial */
    if (dayjs(dateRange[0].endDate).isAfter(dayjs(dateRange[0].startDate).add(1, 'month'))) {
      const startDateYear = dayjs(dateRange[0].startDate).year()
      const endDateYear = dayjs(dateRange[0].endDate).year()
      const startDateMoth = dayjs(dateRange[0].startDate).month()
      const endDateMonth = dayjs(dateRange[0].endDate).month()
      /* aqui evaluo que si el año de la fecha final es mayor que la inicial */
      if (endDateYear > startDateYear) {
        const countMonthsBetweenYears = getNumberMonthsBetweenYears(
          endDateYear,
          startDateYear,
          endDateMonth,
          startDateMoth,
        )
        if (endDateDay > startDateDay) {
          return countMonthsBetweenYears + 2
        }
        return countMonthsBetweenYears + 1
      }
      /* Si el año de la fecha final es igual que la inicial
       * entonces verifico que el dia de la fecha final este despues de la inicial y le suno 1 mes
       * si no los resto */
      if (endDateDay > startDateDay) {
        return endDateMonth - startDateMoth + 1
      }
      return endDateMonth - startDateMoth
    }
    /* aqui evaluo que la fecha final se exactamente 1 mes despues */
    if (dayjs(dateRange[0].endDate).isSame(dayjs(dateRange[0].startDate).add(1, 'month'))) {
      return 1
    }
    /* aqui evaluo que la fecha final este antes del mes */
    if (dayjs(dateRange[0].endDate).isBefore(dayjs(dateRange[0].startDate).add(1, 'month'))) {
      if (startDateDay === endDateDay) {
        return 0
      }
      return 1
    }
    return 0
  }

  const getEstimatedMonthly = () => {
    if (targetAmount && !isShowErrorAmount && numberMonths()) return targetAmount / numberMonths()
    return 0
  }

  const fontWeightBoldForParagraph = 700
  const fontSize = '20px'
  const flexDirection = { xs: 'column', sm: 'row' }
  const gap = { xs: '10px' }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          justifyContent: 'start',
          maxWidth: '470px',
          width: '100%',
          height: '800px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <TextTitle color="info.main" lineHeight="50px">
            {simulatorText.toUpperCase()}
          </TextTitle>
          <TextParagraph fontSize={fontSize} lineHeight="35px">
            {calculateValueMonthlySavingsText}
          </TextParagraph>
        </Box>
        <Box sx={{ marginTop: '40px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Box display="flex" alignItems="center" gap={1}>
            <TextParagraph
              fontWeight={fontWeightBoldForParagraph}
              color="info.main"
              fontSize={fontSize}
            >
              {estimatedMonthlyText}
            </TextParagraph>
            <StyledTooltip title={tooltipTexts.estimatedMonthlyText}>
              <InfoOutlinedIcon color="info" />
            </StyledTooltip>
          </Box>
          <TextTitle color={theme.mixins.greyDark.color} fontSize="40px" fontWeight={900}>
            {convertStringPrice(getEstimatedMonthly())}
          </TextTitle>
        </Box>
        <Box sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <StyledBox sx={{ flexDirection, gap }}>
            <Box display="flex" alignItems="center" gap={1} mt="8px">
              <TextParagraph
                fontWeight={fontWeightBoldForParagraph}
                color="info.main"
                fontSize={fontSize}
              >
                {targetAmountText}
              </TextParagraph>
              <StyledTooltip title={tooltipTexts.targetAmountText}>
                <InfoOutlinedIcon color="info" />
              </StyledTooltip>
            </Box>
            <ContainerBoxFormControl>
              <FormControlApp errorMessage={isShowErrorAmount && labels.simulator.errorAmountText}>
                <StyledInput
                  type="text"
                  error={isShowErrorAmount}
                  value={targetAmount === 0 ? '' : targetAmount}
                  onChange={handleChangeTargetAmount}
                  placeholder={ceroText}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '11px',
                    width: '30px',
                    height: '34px',
                    position: 'absolute',
                    top: '1px',
                    left: '2px',
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '4px 0 0 4px',
                  }}
                >
                  <AttachMoneyIcon color="info" />
                </Box>
              </FormControlApp>
            </ContainerBoxFormControl>
          </StyledBox>
          <StyledBox sx={{ flexDirection, gap }}>
            <Box display="flex" alignItems="center" gap={1} mt="8px">
              <TextParagraph
                fontWeight={fontWeightBoldForParagraph}
                color="info.main"
                fontSize={fontSize}
              >
                {savingsStartlineText}
              </TextParagraph>
              <StyledTooltip title={tooltipTexts.savingsStartlineText}>
                <InfoOutlinedIcon color="info" />
              </StyledTooltip>
            </Box>
            <ClickAwayListener onClickAway={handleCloseCalendar}>
              <Box sx={{ position: 'relative' }}>
                <ContainerBoxFormControl onClick={handleToggleCalendar}>
                  <FormControlApp>
                    <StyledDataPicker value={dateRange[0].startDate} readOnly format="d/MM/yyyy" />
                  </FormControlApp>
                </ContainerBoxFormControl>
                {isCalendarOpen && (
                  <Card
                    elevation={2}
                    sx={{ position: 'absolute', top: 0, left: '105%', borderRadius: '15px' }}
                  >
                    <DateRange
                      minDate={new Date()}
                      months={2}
                      direction={isScreenLowerThanLarge ? 'vertical' : 'horizontal'}
                      ranges={dateRange}
                      onChange={handleChangeDateRange}
                      dateDisplayFormat="d MMM yyyy"
                      locale={es}
                    />
                  </Card>
                )}
              </Box>
            </ClickAwayListener>
          </StyledBox>
          <StyledBox sx={{ flexDirection, gap }}>
            <Box display="flex" alignItems="center" gap={1} mt="8px">
              <TextParagraph
                fontWeight={fontWeightBoldForParagraph}
                color="info.main"
                fontSize={fontSize}
              >
                {savingsDeadlineText}
              </TextParagraph>
              <StyledTooltip title={tooltipTexts.savingsDeadlineText}>
                <InfoOutlinedIcon color="info" />
              </StyledTooltip>
            </Box>
            <ContainerBoxFormControl>
              <FormControlApp>
                <StyledDataPicker
                  value={dateRange[0].endDate}
                  readOnly
                  format="d/MM/yyyy"
                  disableOpenPicker
                />
              </FormControlApp>
            </ContainerBoxFormControl>
          </StyledBox>
          <StyledBox sx={{ flexDirection, gap }}>
            <Box display="flex" alignItems="center" gap={1} mt="8px">
              <TextParagraph
                fontWeight={fontWeightBoldForParagraph}
                color="info.main"
                fontSize={fontSize}
              >
                {numberMonthsText}
              </TextParagraph>
              <StyledTooltip title={tooltipTexts.numberMonthsText}>
                <InfoOutlinedIcon color="info" />
              </StyledTooltip>
            </Box>
            <ContainerBoxFormControl>
              <FormControlApp>
                <InputApp type="text" value={numberMonths()} disabled />
              </FormControlApp>
            </ContainerBoxFormControl>
          </StyledBox>
        </Box>
        <Box mt={7}>
          <TextParagraph>{bottomDisclaimer}</TextParagraph>
        </Box>
      </Box>
    </LocalizationProvider>
  )
}
