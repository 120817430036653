import React, { ReactNode } from 'react'
import { Typography, TypographyProps, useTheme } from '@mui/material'

interface TextProps extends TypographyProps {
  children: ReactNode
  color?: string
  fontSize?: string
  lineHeight?: string
  fontWeight?: number
  fontFamily?: string
}

export function TextTitle({
  children,
  color,
  fontWeight,
  fontSize,
  lineHeight,
  ...rest
}: TextProps) {
  return (
    <Typography
      fontWeight={fontWeight || 700}
      fontSize={fontSize || '30px'}
      lineHeight={lineHeight || '35px'}
      color={color || 'primary'}
      sx={{ ...rest }}
    >
      {children}
    </Typography>
  )
}

export function TextSubTitle({
  children,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  fontFamily,
  ...rest
}: TextProps) {
  return (
    <Typography
      fontWeight={fontWeight || 700}
      fontSize={fontSize || '20px'}
      lineHeight={lineHeight || '24px'}
      color={color || 'primary'}
      fontFamily={fontFamily || 'Roboto'}
      sx={{ ...rest }}
    >
      {children}
    </Typography>
  )
}

export function TextBold({
  children,
  fontWeight,
  color,
  fontSize,
  lineHeight,
  ...rest
}: TextProps) {
  return (
    <Typography
      fontWeight={fontWeight || 500}
      fontSize={fontSize || '20px'}
      lineHeight={lineHeight || '23.44px'}
      color={color || 'primary'}
      sx={{ ...rest }}
    >
      {children}
    </Typography>
  )
}

export function TextParagraph({
  children,
  fontWeight,
  fontSize,
  color,
  lineHeight,
  ...rest
}: TextProps) {
  return (
    <Typography
      fontWeight={fontWeight || 400}
      fontSize={fontSize || '16px'}
      lineHeight={lineHeight || '20px'}
      color={color || 'primary'}
      sx={{ ...rest }}
    >
      {children}
    </Typography>
  )
}

export function PrivacyPolicyText({
  children,
  fontWeight,
  fontSize,
  lineHeight,
  ...rest
}: TextProps) {
  const theme = useTheme()
  return (
    <Typography
      fontWeight={fontWeight || 500}
      fontSize={fontSize || '20px'}
      lineHeight={lineHeight || '23.44px'}
      color={theme.mixins.workSans.color}
      sx={{ ...rest }}
    >
      {children}
    </Typography>
  )
}
