import { readStudentsByRepresentativeQuery } from 'application/queries/representative'
import RepresentativeRepository from 'infraestructure/repositories/representativeRepository'
import {
  ReadRepresentativeAndStudentsQueryRequest,
  UpdateRepresentativeCommandRequest,
} from 'domain/entities/representative'
import { Query } from 'domain/models/query'
import { updateRepresentativeCommand } from 'application/commands/representative/updateRepresentativeCommand'
import { readRepresentativeAndStudentsQuery } from 'application/queries/representative/readRepresentativeAndStudentsQuery'

const representativeRepository = new RepresentativeRepository()

export class RepresentativeController {
  static async getStudentsByRepresentative(query?: Query) {
    return await readStudentsByRepresentativeQuery(representativeRepository, query)
  }

  static async update(representativeId: string, userData: UpdateRepresentativeCommandRequest) {
    return await updateRepresentativeCommand(representativeRepository, representativeId, userData)
  }

  static async getRepresentativeAndStudentsById(params: ReadRepresentativeAndStudentsQueryRequest) {
    return await readRepresentativeAndStudentsQuery(representativeRepository, params)
  }
}
