import {
  dataPrivacyPolicyText,
  eventsPromText,
  frequentQuestionsText,
  termsAndConditionsText,
  tripsPromText,
} from 'presentation/constant/texts/footer'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

export const promItems = [
  {
    id: 1,
    name: tripsPromText,
    url: urlRoutes.public.travel.urlTo,
  },
  {
    id: 2,
    name: eventsPromText,
    url: urlRoutes.public.graduation.urlTo,
  },
]

const frequentQuestionsUrl = `${process.env.REACT_APP_URL_FREQUENT_QUESTIONS_FORMAT}`
const termsAndConditionsUrl = `${process.env.REACT_APP_URL_TERMS_AND_CONDITIONS_FORMAT}`
export const footerLeftGridItems = [
  { id: 1, name: frequentQuestionsText, url: frequentQuestionsUrl },
  { id: 2, name: termsAndConditionsText, url: termsAndConditionsUrl },
]

export const footerRightGridItems = [
  { id: 1, name: dataPrivacyPolicyText, url: urlRoutes.public.footer.dataPrivacyPolicy.urlTo },
]
