const AUTHENTICATION = 'autenticacion'
const LOGIN = 'login'
const CREATE_PASSWORD = 'crear-contrasena'
const RECOVER_PASSWORD = 'recuperar-contrasena'
const FORGOT_PASSWORD = 'cambiar-contrasena'
const TERMS_CONDITIONS = 'terminos-condiciones'
const CONTACTUS = 'contactanos'
const FREQUENT_QUESTIONS = 'preguntas-frecuentes'
const PRIVATE = 'privado'
const SIMULATOR = 'simulador'
const GENERAL_BACKGROUND_HISTORY = 'historial-fondo-general'
const DATA_PRIVACY_POLICY = 'politica-privacidad-datos'
const UPDATE_DATA = 'actualizar-datos'
const DATA_VERIFICATION = 'verificacion-datos'
const TRAVEL = 'viaje'
const GRADUATION = 'graduacion'
const VOTE = 'votacion'

export const urlRoutes = {
  public: {
    url: '/*',
    auth: {
      url: `/${AUTHENTICATION}/*`,
      login: {
        url: `/${LOGIN}`,
        urlTo: `/${AUTHENTICATION}/${LOGIN}`,
      },
      recoverPassword: {
        url: `/${RECOVER_PASSWORD}`,
        urlTo: `/${AUTHENTICATION}/${RECOVER_PASSWORD}`,
      },
      createPassword: {
        url: `/${CREATE_PASSWORD}`,
        urlTo: `/${AUTHENTICATION}/${CREATE_PASSWORD}`,
      },
      forgotPassword: {
        url: `/${FORGOT_PASSWORD}`,
        urlTo: `/${AUTHENTICATION}/${FORGOT_PASSWORD}`,
      },
    },
    footer: {
      url: '/*',
      termsAndConditions: {
        url: `/${TERMS_CONDITIONS}`,
        urlTo: `/${TERMS_CONDITIONS}`,
      },
      contactus: {
        url: `/${CONTACTUS}`,
        urlTo: `/${CONTACTUS}`,
      },
      frequentQuestions: {
        url: `/${FREQUENT_QUESTIONS}`,
        urlTo: `/${FREQUENT_QUESTIONS}`,
      },
      dataPrivacyPolicy: {
        url: `/${DATA_PRIVACY_POLICY}`,
        urlTo: `/${DATA_PRIVACY_POLICY}`,
      },
    },
    travel: {
      url: `/${TRAVEL}`,
      urlTo: `/${TRAVEL}`,
    },
    graduation: {
      url: `/${GRADUATION}`,
      urlTo: `/${GRADUATION}`,
    },
    home: {
      url: '/home',
    },
    simulator: {
      url: `/${SIMULATOR}`,
      urlTo: `/${SIMULATOR}`,
    },
  },
  private: {
    url: `/${PRIVATE}/*`,
    simulator: {
      url: `/${SIMULATOR}`,
      urlTo: `/${PRIVATE}/${SIMULATOR}`,
    },
    generalBackgroundHistory: {
      url: `/${GENERAL_BACKGROUND_HISTORY}`,
      urlTo: `/${PRIVATE}/${GENERAL_BACKGROUND_HISTORY}`,
    },
    updateData: {
      url: `/${UPDATE_DATA}/:representativeId/${DATA_VERIFICATION}/:studentId`,
      urlTo: `/${PRIVATE}/${UPDATE_DATA}`,
    },
    travelVote: {
      url: `/${VOTE}-${TRAVEL}`,
      urlTo: `/${PRIVATE}/${VOTE}-${TRAVEL}`,
    },
  },
}
