import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { Link } from 'react-router-dom'

export const StyledAccordion = styled(MuiAccordion)<AccordionProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiPaper-root': {
    background: `${theme.palette.primary.main}21`,
    borderRadius: 0,
  },
}))

export const StyledAccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryProps>(
  ({ theme }) => ({
    flexDirection: 'row',
    '&.MuiAccordionSummary-root.Mui-expanded': {
      background: theme.palette.info.main,
      color: theme.palette.text.secondary,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
      color: 'white',
    },
  }),
)

export const StyledLink = styled(Link)({
  textDecoration: 'none',
})
