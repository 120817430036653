import { ReactNode } from 'react'
import { Backdrop, useTheme } from '@mui/material'

import { StyledBox } from './index.styled'

type BackdropPopupProps = {
  openBackdrop: boolean
  children: ReactNode | ReactNode[]
  padding?: string
}

export default function BackdropPopup({ openBackdrop, padding, children }: BackdropPopupProps) {
  const theme = useTheme()
  return (
    <Backdrop open={openBackdrop} sx={{ zIndex: 1500 }}>
      <StyledBox padding={padding} theme={theme}>
        {children}
      </StyledBox>
    </Backdrop>
  )
}
