import { MouseEventHandler } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem } from '@mui/material'
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { authUser } from 'presentation/redux/slices/authUserSlice'
import { useLogout } from 'presentation/redux/hooks/user'
import { StyledItemMenuText } from 'presentation/components/layout/components/Header/header.styled'
import {
  HeaderMenu,
  HeaderWrapperNavBarItems,
} from 'presentation/components/layout/components/Header/components'
import { signOffText, updateDataText } from 'presentation/constant/texts/header'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { representativeState } from 'presentation/redux/slices'

type HeaderAccountMenuProps = {
  isOpenUserMenu: boolean
  handleClickListUserItemMenu: MouseEventHandler<HTMLElement>
  handleCloseUserMenu: () => void
  anchorUserEl: any
}

export default function HeaderAccountMenu({
  isOpenUserMenu,
  handleClickListUserItemMenu,
  handleCloseUserMenu,
  anchorUserEl,
}: HeaderAccountMenuProps) {
  const { selectedStudent } = useSelector(representativeState)
  const { logout } = useLogout()
  const { userInfo, refreshToken } = useSelector(authUser)
  const navigate = useNavigate()

  const studentId = selectedStudent?.studentId
  const representativeId = userInfo?.user_id

  const handleLogout = async () => {
    await logout(refreshToken)
  }

  const handleUpdateData = () => {
    navigate(
      `${urlRoutes.private.updateData.urlTo}/${representativeId}/verificacion-datos/${studentId}`,
    )
  }
  return (
    <HeaderWrapperNavBarItems
      icon={<AccountCircleOutlinedIcon color="info" fontSize="large" />}
      handleOpenMenu={handleClickListUserItemMenu}
    >
      <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
        {userInfo?.fullName}
      </StyledItemMenuText>
      <HeaderMenu
        anchorEl={anchorUserEl}
        isOpenMenu={isOpenUserMenu}
        handleCloseMenu={handleCloseUserMenu}
      >
        <MenuItem onClick={handleUpdateData}>{updateDataText} </MenuItem>
        <MenuItem onClick={handleLogout}>{signOffText}</MenuItem>
      </HeaderMenu>
    </HeaderWrapperNavBarItems>
  )
}
