import { Box, useTheme } from '@mui/material'

import { CheckBox } from 'presentation/styles/index.styled'
import { TextParagraph } from 'presentation/components/elements'
import { acceptText, termsAndConditionsText } from 'presentation/constant/globals'

type TermsAndConditionsCheckProps = {
  isChecked: boolean
  handleChangeCheck: () => void
  linkText?: string
  link?: string
}
export default function TermsAndConditionsCheck({
  isChecked,
  handleChangeCheck,
  linkText = termsAndConditionsText,
  link = `${process.env.REACT_APP_URL_TERMS_AND_CONDITIONS_FORMAT}`,
}: TermsAndConditionsCheckProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <CheckBox checked={isChecked} onChange={handleChangeCheck} />
      <TextParagraph fontSize="12px" color="info.light">
        {acceptText}{' '}
        <a
          style={{ color: theme.palette.info.light }}
          href={link}
          target="__blank"
          rel="noreferrer noopener"
        >
          {linkText}
        </a>
      </TextParagraph>
    </Box>
  )
}
