import { AppBar, Box, Toolbar, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import Logo from 'presentation/assets/icons/logoHeader.svg'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { useHeader } from './hooks'
import { HeaderNavItems, HeaderSignInItem } from './components'

function Header() {
  const navigate = useNavigate()
  const theme = useTheme()
  const {
    anchorSignInEl,
    userInfo,
    logged,
    isOpenSignInMenu,
    handleClickListSignInItemMenu,
    handleCloseSignInMenu,
  } = useHeader()

  const handleRedirect = () => {
    if (!userInfo?.firstLogin) {
      navigate(urlRoutes.public.home.url)
    }
  }

  return (
    <AppBar position="fixed" sx={{ borderBottom: `2px solid ${theme.palette.info.main}` }}>
      <Toolbar
        sx={{
          backgroundColor: 'primary',
          alignItems: 'center',
          alignSelf: 'center',
          padding: logged ? '13px 27px' : '15px 27px',
          minHeight: '80px',
          height: 'auto',
          maxWidth: '1700px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            marginRight: '120px',
            cursor: userInfo?.firstLogin ? 'default' : 'pointer',
          }}
          onClick={handleRedirect}
        >
          <img alt="LogoIcon" src={Logo} />
        </Box>
        {logged && !userInfo?.firstLogin ? (
          <HeaderNavItems />
        ) : (
          !logged && (
            <HeaderSignInItem
              handleClickListSignInItemMenu={handleClickListSignInItemMenu}
              anchorSignInEl={anchorSignInEl}
              handleCloseSignInMenu={handleCloseSignInMenu}
              isOpenSignInMenu={isOpenSignInMenu}
            />
          )
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
