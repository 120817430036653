/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit'
// MOCK DATA IMPORTS
import BAVARO from 'presentation/assets/images/viajes/cards/bavaro.png'
import CANCUN from 'presentation/assets/images/viajes/cards/cancun.png'
import COLOMBIA from 'presentation/assets/images/viajes/cards/colombia.png'
import GALAPAGOS from 'presentation/assets/images/viajes/cards/galapagos.png'
import ISRAEL from 'presentation/assets/images/viajes/cards/israel.png'
import MADRID from 'presentation/assets/images/viajes/cards/madrid.png'
import MIAMI_ORLANDO from 'presentation/assets/images/viajes/cards/miami.png'
import PUNTA_CANA from 'presentation/assets/images/viajes/cards/punta_cana.png'
import REPUBLICA_DOMINICANA from 'presentation/assets/images/viajes/cards/rep_dominicana.png'

// MOCK DATA
const mockData = {
  galapagos: [
    {
      id: 1,
      imageUrl: GALAPAGOS,
      title: 'GALÁPAGOS',
      previewText:
        'Es una maravilla natural, con su flora, fauna y un fantástico archipiélago único en el mundo',
      detailViewText:
        'Galápagos es un archipiélago ubicado en Ecuador, en el Océano Pacífico. La región cuenta con una biodiversidad única y muchos animales que no se encuentran en ningún otro lugar del mundo. Se pueden hacer tours de snorkel y buceo para ver tortugas gigantes, iguanas marinas, pingüinos y tiburones. También hay muchas oportunidades para hacer senderismo y explorar las islas. Galápagos es un destino perfecto para los jóvenes que buscan aventura y descubrir la belleza natural de nuestro planeta.',
    },
  ],
  miami: [
    {
      id: 1,
      imageUrl: MIAMI_ORLANDO,
      title: 'MIAMI - ORLANDO',
      previewText:
        'Cumple el sueño de tu hijo/a con la magia que te ofrece Florida en Miami - Orlando',
      detailViewText:
        'Miami y Orlando son dos de las ciudades más populares de Florida para los jóvenes. Miami ofrece una vida nocturna vibrante, hermosas playas, arte callejero, una gran variedad de restaurantes y tiendas, y una escena cultural diversa. Por otro lado, Orlando es el hogar de los parques temáticos de Walt Disney World y Universal Studios, además de contar con numerosas atracciones turísticas y centros comerciales. Ambas ciudades son perfectas para unas vacaciones llenas de diversión y aventura, y están a solo unas horas de distancia en auto o transporte público.',
    },
  ],
  cancun: [
    {
      id: 1,
      imageUrl: CANCUN,
      title: 'CANCÚN',
      previewText: 'Hermoso paraíso,  donde el sol, la playa y la diversión esperan',
      detailViewText:
        'Cancún es una ciudad turística ubicada en la costa caribeña de México, conocida por sus playas de arena blanca y aguas cristalinas. Es un destino popular para vacaciones en familia, entre amigos y viajes de fiesta, con una amplia gama de actividades al aire libre como buceo, snorkel y parasailing. También cuenta con una vida nocturna vibrante y restaurantes que sirven deliciosa comida mexicana e internacional. Además, Cancún está cerca de importantes sitios arqueológicos mayas, como Chichén Itzá y Tulum, lo que lo convierte en una excelente base para explorar la rica cultura e historia de la región.',
    },
  ],
  repDominican: [
    {
      id: 1,
      imageUrl: REPUBLICA_DOMINICANA,
      title: 'REPÚBLICA DOMINICANA',
      previewText: 'Una belleza caribeña fantástica para disfrutar y divertirse',
      detailViewText:
        'República Dominicana es un país ubicado en el Caribe, conocido por sus playas de arena blanca, aguas cristalinas y clima cálido. Es un destino turístico popular que ofrece una amplia variedad de actividades al aire libre, como deportes acuáticos, senderismo y excursiones en buggy. La isla también cuenta con una rica historia y cultura, que se refleja en su arquitectura colonial española y su deliciosa gastronomía. Además, los visitantes pueden disfrutar de la música y el baile típico del merengue y la bachata, y visitar lugares icónicos como la ciudad colonial de Santo Domingo y el Parque Nacional Los Haitises.',
    },
  ],
  madrid: [
    {
      id: 1,
      imageUrl: MADRID,
      title: 'MADRID',
      previewText:
        'La historia, cultura, gastronomía y belleza se unen para crear una experiencia inolvidable',
      detailViewText:
        'Madrid es la capital y la ciudad más grande de España, conocida por su vibrante vida nocturna, deliciosa gastronomía y su rica cultura e historia. La ciudad cuenta con una impresionante arquitectura, desde el majestuoso Palacio Real hasta el moderno edificio de la Torre Picasso. Además, Madrid es el hogar de algunos de los mejores museos de arte del mundo, como el Museo del Prado y el Museo Reina Sofía. También es famosa por sus plazas animadas y hermosos parques, como el Parque del Retiro. Es un destino turístico emocionante y culturalmente enriquecedor para todo tipo de turistas. ',
    },
  ],
  israel: [
    {
      id: 1,
      imageUrl: ISRAEL,
      title: 'ISRAEL',
      previewText:
        'Tierra sagrada, donde la historia y la espiritualidad se fusionan para crear una experiencia única y enriquecedora',
      detailViewText:
        'Israel es un país en Oriente Medio, ubicado en la costa del Mediterráneo. Es un lugar de gran importancia religiosa, cultural e histórica, ya que es considerado sagrado por las tres grandes religiones monoteístas: el judaísmo, el cristianismo y el islamismo. Cuenta con sitios arqueológicos de gran importancia histórica; además el país es famoso por su alta tecnología y emprendimiento, especialmente en Tel Aviv, conocida como la "capital tecnológica" del país. En Israel se puede disfrutar de la cultura, la gastronomía y las playas del Mediterráneo.',
    },
  ],
  bavaro: [
    {
      id: 1,
      imageUrl: BAVARO,
      title: 'BÁVARO',
      previewText:
        'Sus playas de ensueño y la hospitalidad de su gente crean experiencias inolvidables',
      detailViewText:
        'Bávaro es una zona turística ubicada en la costa este de la República Dominicana, conocida por sus playas de arena blanca y aguas cristalinas. Es un destino popular para adolescentes y jóvenes adultos, ya que ofrece una amplia gama de actividades emocionantes, como deportes acuáticos, excursiones en catamarán y fiestas en la playa. Además, cuenta con una vida nocturna vibrante, con bares y discotecas que ofrecen música y entretenimiento para todos los gustos. También hay opciones de compras y restaurantes que sirven deliciosa comida dominicana e internacional. ¡Bávaro es el lugar perfecto para unas vacaciones llenas de diversión y aventura!',
    },
  ],
  puntaCana: [
    {
      id: 1,
      imageUrl: PUNTA_CANA,
      title: 'PUNTA CANA',
      previewText:
        'Destino perfecto para crear momentos inolvidables en un cálido ambiente caribeño',
      detailViewText:
        'Punta Cana es un destino turístico popular en la República Dominicana, especialmente para los jóvenes que buscan sol, playa y fiesta. La zona cuenta con hermosas playas de arena blanca y aguas cristalinas, donde se pueden practicar deportes acuáticos como el snorkel y el surf. También hay una gran variedad de bares y clubes nocturnos que ofrecen música en vivo y fiestas temáticas. Además, se pueden encontrar numerosos restaurantes y tiendas para todos los gustos y presupuestos. ¡Punta Cana es el lugar perfecto para disfrutar de unas vacaciones llenas de diversión y aventura!',
    },
  ],
  colombia: [
    {
      id: 1,
      imageUrl: COLOMBIA,
      title: 'COLOMBIA',
      previewText:
        'Sus paisajes, naturaleza, cultura y gastronomía llenan de sorpresas cada rincón del país',
      detailViewText:
        'Colombia es un país ubicado en el norte de América del Sur, conocido por su diversidad cultural y su rica historia. Es un destino turístico popular para jóvenes, ya que ofrece una amplia gama de experiencias emocionantes, como explorar la naturaleza, disfrutar de la música y la comida típica, y descubrir la vibrante vida nocturna. Colombia cuenta con ciudades modernas como Bogotá, Medellín y Cali, así como playas hermosas y paisajes impresionantes como el Parque Nacional Natural Tayrona y el Valle del Cocora. ¡Colombia es un lugar emocionante para explorar y descubrir nuevas aventuras!',
    },
  ],
}

const initialState = {
  isRequesting: false,
  travelDataContent: mockData,
}
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const travelSlice = createSlice({
  name: 'travel',
  initialState,
  reducers: {
    travelListRequest: (state) => {
      state.isRequesting = true
    },
    travelListSuccess: (state, action) => {
      state.isRequesting = false
      state.travelDataContent = { ...action.payload }
    },
    travelListFail: (state) => {
      state.isRequesting = false
    },
  },
})

export const { travelListRequest, travelListSuccess, travelListFail } = travelSlice.actions
export default travelSlice.reducer
