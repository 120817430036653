import NotificationRepository from 'infraestructure/repositories/notificationRepository'
import { readNotificationsQuery } from 'application/queries/notification'
import { Query } from 'domain/models/query'
import { MarkNotificationsAsReadCommandRequest } from 'domain/entities/notifications'
import { CreateNotificationAsReadCommand } from 'application/commands/notification/createNotificationAsReadCommand'

const notificationsRepository = new NotificationRepository()

export class NotificationController {
  static async getNotifications(query?: Query) {
    return await readNotificationsQuery(notificationsRepository, query)
  }

  static async markAsRead(userData: MarkNotificationsAsReadCommandRequest) {
    return await CreateNotificationAsReadCommand(notificationsRepository, userData)
  }
}
