import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { FirstChangePasswordAuthUserCommandRequest } from 'domain/entities/authUser'
import { Status } from 'domain/models/apiResponse'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import {
  authUser,
  changePasswordError,
  changePasswordStart,
  changePasswordSuccess,
} from 'presentation/redux/slices/authUserSlice'
import { useError } from 'presentation/hooks'

export const useChangePassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(authUser)
  const { getErrorMessage } = useError()

  const changePassword = async (data: FirstChangePasswordAuthUserCommandRequest) => {
    dispatch(changePasswordStart())
    try {
      const response = await AuthUserController.firstChangePassword(data)
      if (response?.status === Status.success) {
        dispatch(changePasswordSuccess())
        navigate(urlRoutes.private.simulator.urlTo)
        return true
      }
    } catch (err) {
      dispatch(changePasswordError(getErrorMessage({ error: err })))
    }
  }

  return { changePassword, isLoading, error }
}
