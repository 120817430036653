import logoDinersClub from 'presentation/assets/icons/logoDinersClub.svg'
import logoDiscover from 'presentation/assets/icons/logoDiscover.svg'
import logoTitanium from 'presentation/assets/icons/logoTitanium.svg'

export const LOGOS = {
  TITANIUM: logoTitanium,
  DISCOVER: logoDiscover,
  DINERS: logoDinersClub,
  PLACE_TO_PAY: 'https://static.placetopay.com/placetopay-logo.svg',
}
