import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaymentSession, paymentSessionEmpty, StudentState } from 'domain/models/student'
import { StudentPaymentResponse } from 'domain/entities/student'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: StudentState = {
  error: null,
  errorVotingInfo: null,
  isLoading: false,
  paymentSession: paymentSessionEmpty,
  student: null,
  isLoadingUpload: false,
  votedForTravel: false,
}
export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    updateProgramedAmountStart: (state: StudentState) => {
      state.isLoading = true
      state.error = null
      state.errorVotingInfo = null
      state.paymentSession = paymentSessionEmpty
    },
    updateProgramedAmountSuccess: (state: StudentState, action: PayloadAction<PaymentSession>) => {
      state.isLoading = false
      state.paymentSession = action.payload
    },
    updateProgramedAmountError: (state: StudentState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateStudentStart: (state: StudentState) => {
      state.isLoadingUpload = true
      state.error = null
      state.errorVotingInfo = null
    },
    updateStudentSuccess: (state: StudentState) => {
      state.isLoadingUpload = false
    },
    updateStudentError: (state: StudentState, action: PayloadAction<string>) => {
      state.isLoadingUpload = false
      state.error = action.payload
    },
    getStudentStart: (state: StudentState) => {
      state.isLoading = true
      state.error = null
      state.student = null
      state.errorVotingInfo = null
    },
    getStudentSuccess: (state: StudentState, action: PayloadAction<StudentPaymentResponse>) => {
      state.isLoading = false
      state.student = action.payload
    },
    getStudentError: (state: StudentState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    getVotingInfoStart: (state: StudentState) => {
      state.isLoading = true
      state.error = null
      state.errorVotingInfo = null
    },
    getVotingInfoSuccess: (state: StudentState, action: PayloadAction<boolean>) => {
      state.isLoading = false
      state.votedForTravel = action.payload
    },
    getVotingInfoError: (state: StudentState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorVotingInfo = action.payload
    },
    clearPaymentSessionStudent: (state: StudentState) => {
      state.paymentSession = paymentSessionEmpty
    },
  },
})

export const studentState = (state: RootState) => state.student
export const {
  updateProgramedAmountStart,
  updateProgramedAmountSuccess,
  updateProgramedAmountError,
  updateStudentStart,
  updateStudentSuccess,
  updateStudentError,
  getStudentError,
  getStudentSuccess,
  getStudentStart,
  getVotingInfoStart,
  getVotingInfoSuccess,
  getVotingInfoError,
  clearPaymentSessionStudent,
} = studentSlice.actions

export default studentSlice.reducer
