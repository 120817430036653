import { ReactNode } from 'react'
import { Grid, GridProps } from '@mui/material'

type GridContainerProps = {
  children: ReactNode
  marginY?: boolean
  justifyCenter?: boolean
  spacing?: number
}

export function GridContainer({
  children,
  marginY,
  spacing,
  justifyCenter,
  ...rest
}: GridContainerProps & GridProps) {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ lg: spacing || 2, md: 2 }}
      direction="row"
      justifyContent={justifyCenter ? 'center' : 'flex-start'}
      marginY={marginY ? 1 : 'none'}
      {...rest}
    >
      {children}
    </Grid>
  )
}

type GridItemProps = {
  children?: ReactNode
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export function GridItem({ children, xs, sm, md, lg, xl }: GridItemProps) {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {children}
    </Grid>
  )
}
