import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationState } from 'domain/models/notification'
import { PaginationEmpty } from 'domain/models/queryPagination'
import { ReadNotificationsQueryResponse } from 'domain/entities/notifications'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: NotificationState = {
  error: null,
  isLoading: false,
  notifications: [],
  pagination: PaginationEmpty,
}
export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotificationsStart: (state: NotificationState) => {
      state.isLoading = true
      state.error = null
    },
    getNotificationsSuccess: (
      state: NotificationState,
      action: PayloadAction<ReadNotificationsQueryResponse>,
    ) => {
      state.isLoading = false
      state.notifications = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getNotificationsError: (state: NotificationState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createNotificationAsReadStart: (state: NotificationState) => {
      state.isLoading = true
      state.error = null
    },
    createNotificationAsReadSuccess: (state: NotificationState) => {
      state.isLoading = false
    },
    createNotificationAsReadError: (state: NotificationState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const notificationsState = (state: RootState) => state.notifications
export const {
  getNotificationsStart,
  getNotificationsSuccess,
  getNotificationsError,
  createNotificationAsReadStart,
  createNotificationAsReadSuccess,
  createNotificationAsReadError,
} = notificationsSlice.actions

export default notificationsSlice.reducer
