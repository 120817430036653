import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RepresentativeController } from 'infraestructure/controllers/representativeController'
import { Query } from 'domain/models/query'
import { Student } from 'domain/entities/representative'
import { Status } from 'domain/models/apiResponse'
import {
  getStudentsByRepresentativeError,
  getStudentsByRepresentativeStart,
  getStudentsByRepresentativeSuccess,
  representativeState,
  selectedRepresentativeChild,
} from 'presentation/redux/slices/representativeSlice'
import { useSaveSelectedStudentInSessionStorage } from 'presentation/redux/hooks/representative'
import { useError } from 'presentation/hooks'

export const useGetStudentsByRepresentative = () => {
  const dispatch = useDispatch()
  const { isLoading, error, selectedStudent } = useSelector(representativeState)
  const { getErrorMessage } = useError()
  const { saveSelectedStudentInSessionStorage } = useSaveSelectedStudentInSessionStorage()

  const getStudentsByRepresentative = useCallback(
    async (query?: Query) => {
      dispatch(getStudentsByRepresentativeStart())
      try {
        const response = await RepresentativeController.getStudentsByRepresentative(query)
        if (response?.status === Status.success) {
          const students = response?.data?.entities as Student[]
          dispatch(getStudentsByRepresentativeSuccess(students))
          if (selectedStudent?.studentId) {
            dispatch(selectedRepresentativeChild(selectedStudent))
            saveSelectedStudentInSessionStorage(selectedStudent)
          } else {
            dispatch(selectedRepresentativeChild(students[0]))
            saveSelectedStudentInSessionStorage(students[0])
          }
        }
      } catch (err) {
        dispatch(getStudentsByRepresentativeError(getErrorMessage({ error: err })))
      }
    },
    [dispatch, getErrorMessage, saveSelectedStudentInSessionStorage, selectedStudent?.studentId],
  )

  return { getStudentsByRepresentative, isLoading, error }
}
