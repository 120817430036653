import { memo, ReactNode, useMemo, useState } from 'react'

import { Student } from 'domain/entities/representative'
import LayoutContext from './LayoutContext'

type LayoutProviderProps = {
  children: ReactNode | ReactNode[]
}

function LayoutProvider({ children }: LayoutProviderProps) {
  const [selectedItemMenu, setSelectedItemMenu] = useState<Student | null>(null)

  const value = useMemo(
    () => ({
      selectedItemMenu,
      setSelectedItemMenu,
    }),
    [selectedItemMenu],
  )

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export default memo(LayoutProvider)
