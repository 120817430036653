import React, { forwardRef } from 'react'
import { createTheme } from '@mui/material/styles'
import { LinkProps } from '@mui/material/Link'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />
})

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#000000',
      light: '#151414',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4A4A4A',
      light: '#898C90',
      dark: '#f3f0f0',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#43B02A',
      light: '#D0ECC9',
      dark: '#2C6424',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#124CFD',
      light: '#4C71FC',
      dark: '#124CFD',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFCA40',
      light: '#FFEEBF',
      dark: '#8A670F',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#E40046',
      light: '#FDE6E5',
      dark: '#D11203',
      contrastText: '#FFFFFF',
    },
    background: { default: '#ffffff', paper: '#F2F5FF' },
    text: { primary: '#000000', secondary: '#ffffff', disabled: '#6D7278' },
  },
  mixins: {
    greyDark: { color: '#5F5F5F' },
    greyLight: { color: '#EDF1FF' },
    scrollBar: { color: '#D9D9D9' },
    grey: { color: '#6D7278' },
    workSans: { color: '#4D4D4D' },
    carrotOrange: { color: '#F79620' },
    outerSpace: { color: 'rgba(74, 74, 74, 1)' },
    blackDark: { color: '#171717' },
    inputPlaceholder: { color: '#B7ADAD' },
    secondaryZircon: { color: '#F0F3FF' },
    greyPrimary: { color: '#D9D9D6' },
    soap: { color: '#44AAFF' },
    rating: { color: '#FFE600' },
  },
  typography: {
    fontFamily: ['"Roboto"'].join(','),
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#000000',
          boxShadow: 'none',
          borderBottom: '2px solid #124CFD',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary',
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          background: '#000000',
          color: '#ffffff',
        },
      },
    },
    /*  MuiPaper: {
                                           styleOverrides: {
                                             root: {
                                               background: '#000000',
                                             },
                                           },
                                         }, */
  },
})
