import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { footerLinkRoutes } from './footerLinkRoutes/footerLinkRoutes'
import { authRoutes } from './authRoutes/authRoutes'
import { graduationRoutes } from './graduationRoutes/graduationRoutes'
import { travelRoutes } from './travelRoutes/travelRoutes'

const NotFound = lazy(() => import('presentation/pages/notFound404'))
const Home = lazy(() => import('presentation/pages/home'))
const PublicSimulator = lazy(() => import('presentation/pages/simulator'))

const HomeRoute = { path: urlRoutes.public.home.url, element: Home }
const PublicSimulatorRoute = { path: urlRoutes.public.simulator.url, element: PublicSimulator }
export const publicRoutes = [
  HomeRoute,
  PublicSimulatorRoute,
  ...authRoutes,
  ...footerLinkRoutes,
  ...travelRoutes,
  ...graduationRoutes,
]

export default function PublicPages() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate to={urlRoutes.public.home.url} replace />} />
      {publicRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={<route.element />} />
      ))}
    </Routes>
  )
}
