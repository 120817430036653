import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'presentation/redux/reducers/rootReducer'
import { PackageState } from 'domain/models/package'
import { PaginationEmpty } from 'domain/models/queryPagination'
import { ReadPackagesQueryResponse } from 'domain/entities/package'

const initialState: PackageState = {
  error: null,
  errorVotePackage: null,
  isLoading: false,
  packages: [],
  pagination: PaginationEmpty,
}
export const packageSlice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    getPackagesStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
      state.errorVotePackage = null
    },
    getPackagesSuccess: (state: PackageState, action: PayloadAction<ReadPackagesQueryResponse>) => {
      state.isLoading = false
      state.packages = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getPackagesError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    votePackageStart: (state: PackageState) => {
      state.isLoading = true
      state.error = null
      state.errorVotePackage = null
    },
    votePackageSuccess: (state: PackageState) => {
      state.isLoading = false
    },
    votePackageError: (state: PackageState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.errorVotePackage = action.payload
    },
    clearAllErrors: (state: PackageState) => {
      state.error = null
      state.errorVotePackage = null
    },
  },
})

export const packageState = (state: RootState) => state.package
export const {
  getPackagesStart,
  getPackagesSuccess,
  getPackagesError,
  votePackageStart,
  votePackageSuccess,
  votePackageError,
  clearAllErrors,
} = packageSlice.actions

export default packageSlice.reducer
