import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginationEmpty } from 'domain/models/queryPagination'
import { PaymentSessionState } from 'domain/models/paymentSession'
import { ReadPaymentSessionsQueryResponse } from 'domain/entities/paymentSession'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: PaymentSessionState = {
  error: null,
  isLoading: false,
  sessions: [],
  pagination: PaginationEmpty,
}
export const paymentSessionSlice = createSlice({
  name: 'paymentSession',
  initialState,
  reducers: {
    getPaymentSessionsStart: (state: PaymentSessionState) => {
      state.isLoading = true
      state.error = null
    },
    getPaymentSessionsSuccess: (
      state: PaymentSessionState,
      action: PayloadAction<ReadPaymentSessionsQueryResponse>,
    ) => {
      state.isLoading = false
      state.sessions = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getPaymentSessionsError: (state: PaymentSessionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const paymentSessionState = (state: RootState) => state.paymentSession
export const { getPaymentSessionsStart, getPaymentSessionsSuccess, getPaymentSessionsError } =
  paymentSessionSlice.actions

export default paymentSessionSlice.reducer
