export type QueryPagination = {
  page: number
  pageSize: number
  total: number
}

export const PaginationEmpty = {
  page: 0,
  pageSize: 0,
  total: 0,
}
