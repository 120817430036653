import { headerText, passwordText } from 'presentation/constant/globals'

export const labels = {
  form: {
    requiredFieldText: 'Este campo no puede estar vacío',
    invalidEmailText: 'El correo electrónico no es válido',
    minCharactersText: 'Debe contener al menos 8 caracteres',
    minSixCharactersText: 'Debe contener al menos 6 caracteres',
    maxCharactersText: 'No debe contener más de 13 caracteres',
    wrongIdentificationNumberText: 'El número de identificación es incorrecto',
    notMatchPasswordsText: `Las ${passwordText}s no coinciden`,
    onlyNumbersText: 'Solo se permiten números',
    equalTenCharactersText: 'Este campo debe tener 10 caracteres',
    maxCharactersIdentificationNumberText: 'Este campo debe tener 10 caracteres',
    onlyLettersText: 'Solo se permiten letras y espacios',
    // eslint-disable-next-line max-len
    wrongPasswordsText: `La ${passwordText.toLowerCase()} debe tener al menos una mayúscula, números, letras y 1 caracter especial`,
    maxCharactersPasswordsText: `La ${passwordText.toLowerCase()} no puede ser mayor que 20`,
    minCharactersIdentificationNumberText: `El número de identificación debe tener al menos 4 caracteres`,
  },
  alert: {},
  /* simulator page */
  simulator: {
    dateText: 'Agregar Fecha',
    errorAmountText: 'Has introducido datos incorrectos',
  },
  /* login page */
  login: {
    identityCardText: 'Número de identificación',
    passwordText: `${passwordText.charAt(0).toUpperCase()}${passwordText.slice(1)}`,
  },
  forgotPassword: {
    repeatPasswordText: `Repetir ${passwordText.toLowerCase()}`,
  },
  recoverPassword: {
    identificationNumberText: 'Número de identificación',
  },
  /* create page */
  createPassword: {
    createText: `${headerText}`,
    confirmText: `Confirmar ${passwordText.toLowerCase()}`,
  },
}
