import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const TravelVote = lazy(() => import('presentation/pages/travelVote'))

export const TravelVoteRoutes = [
  {
    nameUrl: 'Votación Viaje',
    path: urlRoutes.private.travelVote.url,
    element: TravelVote,
    rols: ['*'],
  },
]
