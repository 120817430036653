import { useDispatch, useSelector } from 'react-redux'

import { RepresentativeController } from 'infraestructure/controllers/representativeController'
import { UpdateRepresentativeCommandRequest } from 'domain/entities/representative'
import { Status } from 'domain/models/apiResponse'
import {
  representativeState,
  updateRepresentativeError,
  updateRepresentativeStart,
  updateRepresentativeSuccess,
} from 'presentation/redux/slices/representativeSlice'
import { authUser, updateUserInfo } from 'presentation/redux/slices/authUserSlice'
import useSessionStorage from 'presentation/hooks/useSessionStorage'
import { userKey } from 'presentation/constant/sessionStorageKeys'
import { useError } from 'presentation/hooks'

export const useUpdateRepresentative = () => {
  const dispatch = useDispatch()
  const { isLoadingUpdate, error } = useSelector(representativeState)
  const user = useSelector(authUser)
  const { saveDataAtSessionStorage } = useSessionStorage(userKey)
  const { getErrorMessage } = useError()

  const updateRepresentative = async (
    representativeId: string,
    userData: UpdateRepresentativeCommandRequest,
  ) => {
    dispatch(updateRepresentativeStart())
    try {
      const response = await RepresentativeController.update(representativeId, userData)
      if (response?.status === Status.success) {
        dispatch(updateRepresentativeSuccess())
        const representativeFullName = `${userData.name} ${userData.surname}`
        const fullName = { fullName: representativeFullName }
        const userStorage = {
          logged: user?.logged,
          refreshExpiresIn: user?.refreshExpiresIn,
          refreshToken: user?.refreshToken,
          userInfo: { ...user?.userInfo, ...fullName },
        }
        saveDataAtSessionStorage({ data: userStorage })
        dispatch(updateUserInfo({ ...user?.userInfo, ...fullName }))
        return true
      }
    } catch (err) {
      dispatch(updateRepresentativeError(getErrorMessage({ error: err })))
      return false
    }
  }

  return { updateRepresentative, isLoadingUpdate, error }
}
