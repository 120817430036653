import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationController } from 'infraestructure/controllers/notificationController'
import { Query } from 'domain/models/query'
import { ReadNotificationsQueryResponse } from 'domain/entities/notifications'
import { Status } from 'domain/models/apiResponse'
import {
  getNotificationsError,
  getNotificationsStart,
  getNotificationsSuccess,
  notificationsState,
} from 'presentation/redux/slices/notificationSlice'
import useError from 'presentation/hooks/useError'

export const useNotifications = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(notificationsState)
  const { getErrorMessage } = useError()

  const getNotifications = useCallback(
    async (query?: Query) => {
      dispatch(getNotificationsStart())
      try {
        const response = await NotificationController.getNotifications(query)
        if (response?.status === Status.success) {
          dispatch(getNotificationsSuccess(response.data as ReadNotificationsQueryResponse))
        }
      } catch (err: any) {
        dispatch(getNotificationsError(getErrorMessage({ error: err })))
      }
    },
    [dispatch, getErrorMessage],
  )

  return { getNotifications, isLoading, error }
}
