import { SyntheticEvent, useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Box, Typography, useTheme } from '@mui/material'

import { StyledAccordion, StyledAccordionSummary, StyledLink } from './index.styled'
import { sideBarElements } from './index.constants'

type SideBarProps = {
  valuePanel: string
}

export default function SideBar({ valuePanel }: SideBarProps) {
  const [expanded, setExpanded] = useState<string | false>(valuePanel)
  const theme = useTheme()
  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    if (expanded !== panel) {
      setExpanded(newExpanded ? panel : false)
    }
  }
  const border = `1.3px solid ${theme.palette.primary.main}40`

  return (
    <Box
      sx={{
        width: '320px',
        borderRight: border,
        borderLeft: border,
        height: '100%',
        position: 'fixed',
      }}
    >
      {sideBarElements.map(({ id, route, expandedName, ariaControls, panelId, name }) => (
        <StyledLink to={route} key={id}>
          <StyledAccordion
            expanded={expanded === expandedName}
            disableGutters
            elevation={0}
            onChange={handleChange(expandedName)}
          >
            <StyledAccordionSummary
              aria-controls={ariaControls}
              id={panelId}
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            >
              <Typography>{name}</Typography>
            </StyledAccordionSummary>
          </StyledAccordion>
        </StyledLink>
      ))}
    </Box>
  )
}
