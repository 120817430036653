import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const TravelPage = lazy(() => import('presentation/pages/travel'))

export const travelRoutes = [
  {
    path: urlRoutes.public.travel.url,
    element: TravelPage,
  },
]
