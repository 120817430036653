import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Student } from 'domain/entities/representative'
import { authUser, representativeState } from 'presentation/redux/slices'
import {
  useGetStudentsByRepresentative,
  useSaveSelectedStudentInSessionStorage,
} from 'presentation/redux/hooks/representative'
import useLayoutContextStates from 'presentation/components/layout/context/hooks/useLayoutContextStates'

export const useHeader = () => {
  const [anchorStudentEl, setAnchorStudentEl] = useState<null | HTMLElement>(null)
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState<null | HTMLElement>(null)
  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null)
  const [anchorSignInEl, setAnchorSignInEl] = useState<null | HTMLElement>(null)
  const { selectedStudent, students } = useSelector(representativeState)
  const { logged, userInfo } = useSelector(authUser)
  const { error, getStudentsByRepresentative } = useGetStudentsByRepresentative()
  const { saveSelectedStudentInSessionStorage } = useSaveSelectedStudentInSessionStorage()
  const { selectedItemMenu, setSelectedItemMenu } = useLayoutContextStates()

  const isOpenStudentMenu = !!anchorStudentEl
  const isOpenNotificationsMenu = !!anchorNotificationsEl
  const isOpenUserMenu = !!anchorUserEl
  const isOpenSignInMenu = !!anchorSignInEl

  const handleGetStudentsByRepresentative = useCallback(async () => {
    if (logged) {
      await getStudentsByRepresentative()
    }
  }, [getStudentsByRepresentative, logged])

  useEffect(() => {
    handleGetStudentsByRepresentative()
  }, [])

  useEffect(() => {
    if (selectedStudent?.studentId) {
      setSelectedItemMenu(selectedStudent)
    }
  }, [selectedStudent?.studentId])

  const handleCloseStudentMenu = () => {
    setAnchorStudentEl(null)
  }
  const handleCloseSignInMenu = () => {
    setAnchorSignInEl(null)
  }
  const handleCloseUserMenu = () => {
    setAnchorUserEl(null)
  }
  const handleCloseNotificationsMenu = () => {
    setAnchorNotificationsEl(null)
  }
  const handleClickItemMenu = (event: MouseEvent<HTMLElement>, item: Student) => {
    saveSelectedStudentInSessionStorage(item)
    setSelectedItemMenu(item)
    handleCloseStudentMenu()
  }

  const handleClickListSignInItemMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorSignInEl(isOpenSignInMenu ? null : event.currentTarget)
  }
  const handleClickListStudentItemMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorStudentEl(isOpenStudentMenu ? null : event.currentTarget)
    if (anchorNotificationsEl) {
      setAnchorNotificationsEl(null)
    }
    if (anchorUserEl) {
      setAnchorUserEl(null)
    }
  }

  const handleClickListNotificationsItemMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorNotificationsEl(isOpenNotificationsMenu ? null : event.currentTarget)
    if (anchorStudentEl) {
      setAnchorStudentEl(null)
    }
    if (anchorUserEl) {
      setAnchorUserEl(null)
    }
  }

  const handleClickListUserItemMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorUserEl(isOpenUserMenu ? null : event.currentTarget)
    if (anchorNotificationsEl) {
      setAnchorNotificationsEl(null)
    }
    if (anchorNotificationsEl) {
      setAnchorNotificationsEl(null)
    }
  }
  return {
    handleClickListNotificationsItemMenu,
    handleClickListUserItemMenu,
    handleClickListStudentItemMenu,
    handleClickListSignInItemMenu,
    handleClickItemMenu,
    handleCloseNotificationsMenu,
    handleCloseSignInMenu,
    handleCloseUserMenu,
    handleCloseStudentMenu,
    error,
    selectedItemMenu,
    anchorStudentEl,
    anchorNotificationsEl,
    anchorUserEl,
    anchorSignInEl,
    isOpenNotificationsMenu,
    isOpenSignInMenu,
    isOpenStudentMenu,
    isOpenUserMenu,
    userInfo,
    logged,
    students,
  }
}
