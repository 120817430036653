import {
  ReadRepresentativeAndStudentsQueryRequest,
  ReadRepresentativeAndStudentsQueryResponse,
  ReadStudentsByRepresentativeQueryResponse,
  UpdateRepresentativeCommandRequest,
  UpdateRepresentativeCommandResponse,
} from 'domain/entities/representative'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import IRepresentativeRepository from 'domain/interfaces/repositories/IRepresentativeRepository'
import { Query } from 'domain/models/query'
import { getQueryString } from 'infraestructure/utils/format'
import {
  CORE_API_BASE,
  REPRESENTATIVES,
  REPRESENTATIVES_GET_STUDENTS_ENDPOINT,
} from './endpoints.routes'

export default class RepresentativeRepository implements IRepresentativeRepository {
  async getStudentsByRepresentative(
    query: Query,
  ): AsyncApiResponse<ReadStudentsByRepresentativeQueryResponse> {
    const request = {
      get: {
        path: `${REPRESENTATIVES_GET_STUDENTS_ENDPOINT}${
          query ? `?orders=createAt__DESC&${getQueryString(query)}` : `?orders=createAt__DESC&`
        }`,
      },
    }
    return await sendRequest<ReadStudentsByRepresentativeQueryResponse>(request)
  }

  async update(
    representativeId: string,
    data: UpdateRepresentativeCommandRequest,
  ): AsyncApiResponse<UpdateRepresentativeCommandResponse> {
    const request = {
      post: {
        path: `${CORE_API_BASE}/${REPRESENTATIVES}/${representativeId}/update`,
        body: data,
      },
    }
    return await sendRequest<UpdateRepresentativeCommandResponse>(request)
  }

  async getRepresentativeAndStudentsById(
    params: ReadRepresentativeAndStudentsQueryRequest,
  ): AsyncApiResponse<ReadRepresentativeAndStudentsQueryResponse> {
    const request = {
      get: {
        path: `${CORE_API_BASE}/${params.promotionId}/${REPRESENTATIVES}/${params.representativeId}`,
      },
    }
    return await sendRequest<ReadRepresentativeAndStudentsQueryResponse>(request)
  }
}
