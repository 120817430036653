export const FilterOperatorEQ = () => 'EQ'

export const FilterOperator = {
  equal: 'EQ',
  notEqual: 'NEQ',
  greaterEqual: 'GTE',
  lessEqual: 'LTE',
  greater: 'GT',
  less: 'LT',
  contains: 'CONTAINS',
  notContains: 'NOT_CONTAINS',
  relatedEqual: 'RELATED_EQ',
  relatedContains: 'RELATED_CONTAINS',
}
