export const headerText = 'Contraseña'
export const passwordText = 'contraseña' //NOSONAR
export const sendText = 'Enviar'
export const termsAndConditionsText = 'Términos y condiciones'
export const simulatorText = 'Simulador'
export const loginText = 'Iniciar sesión'
export const getIntoText = 'Ingresar'
export const seeMoreText = 'Ver más'
export const dataPrivacyPolicyText = 'Política de privacidad de datos'
export const cancelText = 'CANCELAR'
export const downloadText = 'DESCARGAR'
export const acceptText = 'Aceptar'
export const APPROVED_TEXT = 'Aprobada'
export const continueText = 'CONTINUAR'
export const requestPass = 'Solicitar contraseña'
export const backButtonText = 'REGRESAR'
export const placeholderPasswordText =
  'Mínimo 8 caracteres alfanuméricos, al menos 1 mayúscula y 1 caracter especial'
