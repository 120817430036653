import { Component, ErrorInfo, ReactNode } from 'react'

type ErrorBoundaryProps = {
  children: ReactNode
  fallBackComponent: ReactNode
  resetCondition?: any
  error?: boolean
}

interface State {
  hasError: boolean
  resetCondition: any
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, resetCondition: props.resetCondition }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  static getDerivedStateFromProps(props: ErrorBoundaryProps, state: State) {
    if (props.resetCondition !== state.resetCondition) {
      return { hasError: false, resetCondition: props.resetCondition }
    }
    return null
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    return { hasError: true }
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError || this.props.error) {
      // eslint-disable-next-line react/destructuring-assignment
      return this.props.fallBackComponent
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children
  }
}
