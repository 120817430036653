import { combineReducers } from '@reduxjs/toolkit'

import authUserReducer from 'presentation/redux/slices/authUserSlice'
import representativeReducer from 'presentation/redux/slices/representativeSlice'
import eventMovementsReducer from 'presentation/redux/slices/eventMovementsSlice'
import additionalSavingsReducer from 'presentation/redux/slices/additionalSavingsSlice'
import promotionReducer from 'presentation/redux/slices/promotionSlice'
import placeToPayReducer from 'presentation/redux/slices/placeToPaySlice'
import paymentSessionReducer from 'presentation/redux/slices/paymentSessionSlice'
import studentReducer from 'presentation/redux/slices/studentSlice'
import notificationsReducer from 'presentation/redux/slices/notificationSlice'
import travelReducer from 'presentation/redux/slices/travelSlice'
import graduationReducer from 'presentation/redux/slices/graduationSlice'
import carouselReducer from 'presentation/redux/slices/carouselSlice'
import packageReducer from 'presentation/redux/slices/packageSlice'

const rootReducer = combineReducers({
  authUser: authUserReducer,
  representative: representativeReducer,
  eventMovements: eventMovementsReducer,
  additionalSavings: additionalSavingsReducer,
  promotion: promotionReducer,
  placeToPay: placeToPayReducer,
  paymentSession: paymentSessionReducer,
  student: studentReducer,
  notifications: notificationsReducer,
  travel: travelReducer,
  graduation: graduationReducer,
  carousel: carouselReducer,
  package: packageReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
