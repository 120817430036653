import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import INotificationsRepository from 'domain/interfaces/repositories/INotificationsRepository'
import {
  MarkNotificationsAsReadCommandRequest,
  MarkNotificationsAsReadCommandResponse,
  ReadNotificationsQueryResponse,
} from 'domain/entities/notifications'
import { Query } from 'domain/models/query'
import { getQueryString } from 'infraestructure/utils/format'
import { GET_ALL_NOTIFICATIONS_ENDPOINT } from './endpoints.routes'

export default class NotificationRepository implements INotificationsRepository {
  async getNotifications(query?: Query): AsyncApiResponse<ReadNotificationsQueryResponse> {
    const request = {
      get: {
        path: `${GET_ALL_NOTIFICATIONS_ENDPOINT}${query ? `?${getQueryString(query)}` : ''}`,
      },
    }
    return await sendRequest<ReadNotificationsQueryResponse>(request)
  }

  async markAsRead(
    userData: MarkNotificationsAsReadCommandRequest,
  ): AsyncApiResponse<MarkNotificationsAsReadCommandResponse> {
    const request = {
      post: {
        path: `${GET_ALL_NOTIFICATIONS_ENDPOINT}/mark-as-read`,
        body: userData,
      },
    }
    return await sendRequest<MarkNotificationsAsReadCommandResponse>(request)
  }
}
