import { memo, ReactNode } from 'react'
import { Box } from '@mui/material'
import './style.css'

import { Footer, Header } from './components'
import LayoutProvider from './context/LayoutProvider'

type LayoutProps = {
  children: ReactNode | ReactNode[]
}

function Layout({ children }: LayoutProps) {
  return (
    <LayoutProvider>
      <Box
        sx={{ display: 'flex', height: '100vh', flexDirection: 'column', scrollbarWidth: 'none' }}
      >
        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            marginTop: '80px',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
        <Footer />
      </Box>
    </LayoutProvider>
  )
}

export default memo(Layout)
