import { ChangeEventHandler, useState } from 'react'
import { Box, IconButton, useTheme } from '@mui/material'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

import { InputApp } from 'presentation/styles/index.styled'

const useStyles = makeStyles({
  placeholder: {
    '&::placeholder': {
      fontSize: '8.9px',
      fontWeight: 400,
      lineHeight: '8px',
      color: 'B7ADAD',
    },
  },
})

type PasswordInputProps = {
  name: string
  value: string
  onChangeInput: ChangeEventHandler
  placeholder?: string
}

export default function PasswordInput({
  name,
  value,
  onChangeInput,
  placeholder,
}: PasswordInputProps) {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const theme = useTheme()
  const handleShowPassword = () => setIsShowPassword(!isShowPassword)
  const colorIcon = theme.palette.info.light
  const classes = useStyles()

  return (
    <>
      <InputApp
        type={isShowPassword ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={onChangeInput}
        placeholder={placeholder}
        inputProps={{ className: classes.placeholder }}
      />
      <Box sx={{ position: 'absolute', top: '18px', right: 0 }}>
        <IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
          {isShowPassword ? (
            <VisibilityIcon sx={{ color: colorIcon }} />
          ) : (
            <VisibilityOffIcon sx={{ color: colorIcon }} />
          )}
        </IconButton>
      </Box>
    </>
  )
}
