import { ApiResponse } from 'domain/models/apiResponse'

export function makeErrorResponse<T>(error: any): ApiResponse<T> {
  if (error?.response?.data instanceof ArrayBuffer) {
    const blob = new Blob([error?.response?.data])
    return {
      error: {
        status: error.response?.status ?? 500,
        statusText: error.response?.statusText ?? '',
        content: blob,
      },
    }
  }
  return {
    error: {
      status: error.response?.status ?? 500,
      statusText: error.response?.statusText ?? '',
      content: error.response?.data ?? null,
    },
  }
}
