export const targetAmountText = 'Ahorro Total Proyectado'
export const savingsStartlineText = 'Fecha inicio de ahorro'
export const savingsDeadlineText = 'Fecha límite de ahorro'
export const numberMonthsText = 'Número de cuotas'
export const calculateValueMonthlySavingsText = 'Calcula el valor de tu ahorro mensual'
export const estimatedMonthlyText = 'Cuotas de ahorro mensual'
export const bottomDisclaimer = '*Están sujetos a términos y condiciones'

export const simulatorText = 'Simulador'

export const tooltipTexts = {
  estimatedMonthlyText: 'Es el monto fijo que vas a ahorrar cada mes',
  targetAmountText: 'Es el monto total que deseas ahorrar al final del período',
  savingsStartlineText: 'Fecha en la cual se hará el primer abono al fondo de ahorro',
  savingsDeadlineText: 'Fecha en la cual se planifica realizar el último abono',
  numberMonthsText:
    'Cantidad de cuotas que existen entre la fecha de inicio y la fecha límite de ahorro',
}
export const promotionGoalText = 'METAS DE LA PROMOCIÓN'
