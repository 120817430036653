import { Representative, Student } from 'domain/entities/representative'

export const StudentEmpty = {
  studentId: '',
  studentFullName: '',
  lastPayment: '',
  lastDigits: '',
  payerName: '',
  paymentSession: false,
  programedAmount: 0,
  promotion: {
    promotionId: '',
    institutionId: '',
    name: '',
    accountNumber: '',
    businessName: '',
    ruc: '',
    email: '',
  },
  funds: 0,
}

export type RepresentativeState = {
  error: string | null
  isLoading: boolean
  isLoadingUpdate: boolean
  students: Student[]
  representative: Representative | null
  selectedStudent: Student
}

export type PayloadUpdateStudentSelected = {
  name: string
  surname: string
  studentId: string
}
