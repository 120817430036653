import { Navigate, Outlet } from 'react-router-dom'

import { urlRoutes } from './config/urlRoutes/urlRoutes'

type PrivateRoutesProps = {
  isLoggedIn: boolean
}

function PrivateRoutes({ isLoggedIn }: PrivateRoutesProps) {
  return isLoggedIn ? <Outlet /> : <Navigate to={urlRoutes.public.auth.login.urlTo} />
}

export default PrivateRoutes
