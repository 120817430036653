import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { Divider, Menu, MenuItem } from '@mui/material'

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontsize: '12px',
  lineHeight: '14.06px',
  color: theme.palette.info.main,
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.info.main,
}))

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: '10px',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  color: theme.palette.text.secondary,
  '& .MuiMenuItem-root': {
    fontSize: '14px',
    lineHeight: '18.06px',
    fontWeight: 700,
    marginLeft: '-17px',
  },
  '& .MuiMenuItem-root.Mui-selected': {
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  '& .MuiMenuItem-root:hover': {
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  flexShrink: 0,
  borderWidth: '0px 0px thin',
  borderStyle: 'solid',
  borderColor: theme.palette.background.default,
}))
