import { Box } from '@mui/material'

import { ButtonApp, TextBold } from 'presentation/components/elements'

function ErrorFallback() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          maxWidth: '450px',
          gap: '50px',
          width: '100',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          padding: '100px 50px',
        }}
      >
        <TextBold color="info.main">Lo sentimos. Ha ocurrido un error inesperado.</TextBold>
        <ButtonApp onClick={() => window.location.reload()}>Inténtalo otra vez.</ButtonApp>
      </Box>
    </Box>
  )
}

export default ErrorFallback
