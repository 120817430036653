import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

export const HeadTextContainerBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '20px',
  marginLeft: '130px',
  paddingTop: '105.38px',
  '> :last-child': {
    maxWidth: '336px',
  },
})
