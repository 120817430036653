import { Box, IconButton } from '@mui/material'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material'
import { StyledAlert, StyledSnackbar } from './index.styled'

type CustomizedSnackbarsProps = {
  openSnackbar: boolean
  autoHideDuration?: number
  handleCloseSnackbar: () => void
  isSuccess?: boolean
  message: string
}

export default function CustomizedSnackbar({
  openSnackbar = false,
  autoHideDuration,
  handleCloseSnackbar,
  isSuccess = false,
  message,
}: CustomizedSnackbarsProps) {
  const vertical = 'top'
  const horizontal = 'right'

  return (
    <StyledSnackbar
      open={openSnackbar}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleCloseSnackbar}
    >
      <StyledAlert
        variant="outlined"
        severity={isSuccess ? 'info' : 'error'}
        elevation={6}
        icon={false}
        action={
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <IconButton aria-label="close" size="small" onClick={() => handleCloseSnackbar()}>
              {isSuccess ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <HighlightOffIcon color="error" />
              )}
            </IconButton>
          </Box>
        }
      >
        {message}
      </StyledAlert>
    </StyledSnackbar>
  )
}
