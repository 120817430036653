import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const PrivateSimulator = lazy(() => import('presentation/pages/privateSimulator'))

export const privateSimulatorRoutes = [
  {
    nameUrl: 'Simulador Privado',
    path: urlRoutes.private.simulator.url,
    element: PrivateSimulator,
    rols: ['*'],
  },
]
