import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RepresentativeController } from 'infraestructure/controllers/representativeController'
import {
  ReadRepresentativeAndStudentsQueryRequest,
  ReadRepresentativeAndStudentsQueryResponse,
} from 'domain/entities/representative'
import { Status } from 'domain/models/apiResponse'
import {
  getGetRepresentativeError,
  getGetRepresentativeStart,
  getGetRepresentativeSuccess,
  representativeState,
} from 'presentation/redux/slices/representativeSlice'
import { useError } from 'presentation/hooks'

export const useGetRepresentative = () => {
  const [isSuccessResponse, setIsSuccessResponse] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(representativeState)
  const { getErrorMessage } = useError()
  const getRepresentative = useCallback(
    async (params: ReadRepresentativeAndStudentsQueryRequest) => {
      dispatch(getGetRepresentativeStart())
      try {
        const response = await RepresentativeController.getRepresentativeAndStudentsById(params)
        if (response?.status === Status.success) {
          const data = response.data as ReadRepresentativeAndStudentsQueryResponse
          dispatch(getGetRepresentativeSuccess(data.representative))
          setIsSuccessResponse(true)
        }
      } catch (err) {
        dispatch(getGetRepresentativeError(getErrorMessage({ error: err })))
        setIsSuccessResponse(false)
      }
    },
    [dispatch, getErrorMessage],
  )

  return { getRepresentative, isLoading, error, isSuccessResponse }
}
