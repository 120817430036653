import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const GraduationPage = lazy(() => import('presentation/pages/graduation'))

export const graduationRoutes = [
  {
    path: urlRoutes.public.graduation.url,
    element: GraduationPage,
  },
]
