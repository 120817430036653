/* eslint-disable max-len */
/* *******  GeneralBackgroundHistory Page  ******** */

import {
  cancelText as cancelGlobalText,
  downloadText as downloadGlobalText,
} from 'presentation/constant/globals'

export const scheduledSavingsTabText = 'Ahorro Programado'
export const doIWantMyCardTabText = '¿Quiero mi tarjeta?'
export const backgroundQueryTabText = 'Consulta de Fondo'
export const paymentByTransferTabText = 'Abono por Transferencia'
export const totalSavingText = ' ahorro TOTAL'

/* ******* ScheduledSavingsTab component ******** */
export const programYourSavingsWithDinersClubText = 'Programa tu ahorro con Diners Club'
export const toMakeYourSavingText = 'Para realizar tu ahorro programado utiliza tus '
export const dinersCardsText = 'tarjetas Diners Club.'
export const theFirstChargeText =
  'El primer cargo a tu tarjeta se realiza al momento de tu afiliación y los próximos cargos se llevarán a cabo el día 30 de cada mes'
export const howToScheduleYourSavingsText = '¿Cómo programar tu ahorro?'
export const scheduledSavingsText = 'ahorro programado'
export const scheduledSavingsButtonText = 'Programar ahorro'
export const clickOnTheButtonText = 'Da click en '
export const acceptTermsAndConditionsText = 'Acepta términos y condiciones'
export const enterPlaceToPayText = 'Ingresa a Place to Pay'
export const completeTheInfoText = 'Completa la información requerida de tu tarjeta'
export const defineYourAmountText = 'Define tu monto programado'
export const dinersBankCards = 'Tarjetas Banco Diners Club del Ecuador*'

export const cardVerificationText = 'PRIMER CARGO PROGRAMADO'
export const weAreValidatingTheCardEnteredText =
  'Recuerda que el primer cargo recurrente se llevará a cabo una vez que aceptes los términos y condiciones y des click en el botón “Continuar”.'

/* ******* Transaction Details Popup component ******** */
export const transactionDetailsText = {
  title: {
    '': '',
    Aprobada: 'Transacción exitosa',
    Rechazada: 'Transacción rechazada',
    Pendiente: 'Transacción pendiente',
  },
  subtitle: {
    '': '',
    Aprobada: '',
    Rechazada: 'Verifica los datos de tu tarjeta o modifica el método de pago.',
    Pendiente:
      'Tu pago esta siendo procesado. Te notificaremos la aprobación o rechazo del pago una vez validada la información.',
  },
}
export const DateText = 'Fecha'
export const amountText = 'Monto'
export const statusText = 'Estado'

/* ******* Error In Transaction Popup component ******** */
export const descriptionText = 'Su transacción ha sido'
export const referenceText = 'con referencia:'
export const valueText = 'con valor:'
export const titleText = 'Detalles del fallo de su transacción'

/* ******* Change Programed AmountPopup component ******** */
export const dateProgramedAmountText =
  'Recuerda que el cobro del nuevo monto se llevará a cabo el fin de mes. Si estás de acuerdo con el valor ingresado da  click en el botón Aceptar'
export const informationTitleText = 'CAMBIO DE MONTO'

/* ******* Change Payment Method Popup component ******** */
export const changePaymentMethodSuccessText = 'Datos agregados exitosamente'
export const changePaymentMethodWrongText = 'Error al agregar sus datos'
export const youAreAboutChangeYourMediumText =
  'Se ha realizado el registro de una nueva tarjeta en la plataforma, para continuar acepta términos y condiciones, política de privacidad de datos y da click en el botón “Continuar”.'
export const changePaymentMethodText = 'CAMBIO DE TARJETA'

/* ******* ScheduledSavingsTabDetailProgramedSavings component ******** */
export const detailsOfYourProgrammedSavingsText = 'DETALLES DE TU AHORRO PROGRAMADO'
export const belowAllScheduledSavingsTransactionsText =
  'A continuación,  se encuentra desglosadas todas las transacciones de tu Ahorro Programado hasta la fecha.'
export const currentFormOfPaymentText = 'Forma de pago actual'
export const youAreMakingSavingsDinersCardText = 'Tarjeta asociada'
export const payerNameText = 'Titular'
export const rememberText = '¡RECUERDA!'
export const ifYouNeedItChangeCardText = 'Puedes cambiar tu tarjeta en cualquier momento'
export const changeYourCardText = 'Cambia tu tarjeta'
export const currentScheduledChargeAmountText = 'Monto del cargo programado actual'
export const yourCurrentScheduledSavingsAmountText = 'Tu monto de ahorro programado actual es:'
export const editMountText = 'Editar monto'
export const referenceNumberText = 'Referencia'
export const yourNextScheduledChargeWillRunOnText = 'Tu próximo cargo programado se ejecutará el:'
export const transactionDetailText = 'Detalle de Transacciones'
export const youDoNotHaveAnyAmountYetText = 'Todavía no tiene ningún monto programado'

/* ******* MoneyCardApplicationTab component ******** */
export const weHaveACardToYouText = 'Tenemos una tarjeta para ti'
export const enjoyGreatBenefitsText =
  'Hazte Socio y descubre todos los beneficios de nuestras tarjetas Diners Club y vive las mejores experiencias'
export const joinTheClubText = 'ÚNETE AL CLUB'

/* ******* BackgroundQueryTab component ******** */
export const students = 'Estudiantes'
export const event = 'Fiesta'
export const travel = 'viaje'
export const total = 'total'
export const projected = 'Proyectados'
export const individualGoal = 'Meta individual'
export const individualTravelGoalText = `${individualGoal} ${travel.toUpperCase()}`
export const individualEventGoalText = `${individualGoal} ${event.toUpperCase()}`
export const individualTotalGoalText = `${individualGoal} ${total.toUpperCase()}`
export const projectedNumberStudentsTravelText = `Nro. ${students} ${projected} ${travel.toUpperCase()}`
export const projectedNumberStudentsEventText = `Nro. ${students} ${projected} ${event.toUpperCase()}`
export const savingsEndDateText = 'Fecha de finalización de ahorro:'
export const projectedIndividualGoalsText = 'Metas individuales Proyectadas'
export const individualFundInformationText = 'Información del fondo individual'
export const totalAdditionalSavingsText = 'Ahorro adicional total'
export const detailTotalEventMovementsText = 'Detalle de movimientos total'
export const detailTotalTripMovementsText = 'Detalle de movimientos viaje total'
export const backgroundCancellationText = 'CANCELACIÓN DE FONDO'
export const generalBackgroundDedatilText = 'DETALLE DE FONDO GENERAL'
export const cancelText = cancelGlobalText
export const saveText = 'Guardar'
export const downloadText = downloadGlobalText
export const acceptText = 'Aceptar'
export const continueText = 'Continuar'
export const whenYouClickText = 'Al momento de dar click en el botón '
export const youCanDownloadAapplicationFormText =
  ' , puedes descargar el formato de solicitud de retiro de fondos. Este documento debe ser revisado y firmado por el solicitante y dos miembros del comité. Posteriormente, se entregará de manera física a la fiduciaria.'
export const informationWhenTableDoNotDataText = 'Actualmente no hay detalles que mostrar'
export const youCanEnableOrDisableTheDisplayText =
  'Puedes activar o desactivar la visualización de esta sección para los representes'

/* ******* PaymentByTransferTab component ******** */
export const ifYouDoNotHaveADinersClubCardText =
  'Si no cuentas con una tarjeta Diners Club del Ecuador, puedes realizar tu ahorro por medio de tranferencias bancarias.\n'
export const stepsToFollowText = 'Pasos a seguir'
export const scheduledSubscriptionText = 'Ahorro Programado'
export const ifYouHaveProgrammedSavingText =
  'Si cuentas con un ahorro programado, desactívalo en la pestaña'
export const checkBankDetailsOnYourRightText = 'Revisa los datos bancarios a tu derecha'
export const makeTheTransferText = 'Realiza la transferencia'
export const captureYourTransferReceiptText =
  'Realiza una captura del comprobante de tu transferencia'
export const downloadTheAttachedFormatText = 'Descarga el formato adjunto'
export const fillTheRespectiveInformationText = 'Llena la información respectiva'
export const sendTheCaptureAndTheFileText =
  'Envía la captura y el archivo al correo transferencias@entretodos.ec, con el asunto TRANSFERENCIA ENTRE TODOS (nombre del representante con el detalle del pago: fecha, monto, estudiante)'
export const afterMakingTheTransferText =
  'Después de realizar la transferencia, RECUERDA descargar el formato disponible en el siguiente botón y envía el documento lleno al correo transferencias@entretodos.ec.'
export const afterMakingTheTransferText2 =
  'Después de realizar la transferencia, RECUERDA descargar el formato disponible en el siguiente botón y envía el documento lleno al correo transferencias@entretodos.ec'

export const downloadFormatText = `${downloadGlobalText} formato`
export const bankText = 'BANCO DINERS CLUB – CUENTA DE AHORROS'
export const accountNumberText = 'Número de Cuenta'
export const businessNameText = 'Razon Social'
export const rcuText = 'RUC'
export const emailText = 'Correo electrónico'

export const alertPaymentMethodText = 'Cambio de tarjeta exitoso'
export const addNewCardCorrectlyText =
  // eslint-disable-next-line max-len
  'Se ha añadido su nueva tarjeta correctamente. Haga click en el botón “Continuar”. para seguir navegando'
