import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationController } from 'infraestructure/controllers/notificationController'
import { MarkNotificationsAsReadCommandRequest } from 'domain/entities/notifications'
import { Status } from 'domain/models/apiResponse'
import {
  createNotificationAsReadError,
  createNotificationAsReadStart,
  createNotificationAsReadSuccess,
  notificationsState,
} from 'presentation/redux/slices/notificationSlice'
import useError from 'presentation/hooks/useError'

export const useMarkNotificationsAsRead = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(notificationsState)
  const { getErrorMessage } = useError()

  const markNotificationsAsRead = useCallback(
    async (data: MarkNotificationsAsReadCommandRequest) => {
      dispatch(createNotificationAsReadStart())
      try {
        const response = await NotificationController.markAsRead(data)
        if (response?.status === Status.success) {
          dispatch(createNotificationAsReadSuccess())
        }
      } catch (err: any) {
        dispatch(createNotificationAsReadError(getErrorMessage({ error: err })))
      }
    },
    [dispatch, getErrorMessage],
  )

  return { markNotificationsAsRead, isLoading, error }
}
