import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const Login = lazy(() => import('presentation/pages/auth/login'))
const RecoverPassword = lazy(() => import('presentation/pages/auth/recoverPassword'))
const CreatePassword = lazy(() => import('presentation/pages/auth/createPassword'))
export const authRoutes = [
  { path: urlRoutes.public.auth.login.url, element: Login },
  { path: urlRoutes.public.auth.recoverPassword.url, element: RecoverPassword },
  { path: urlRoutes.public.auth.createPassword.url, element: CreatePassword },
  {
    path: urlRoutes.public.auth.forgotPassword.url,
    element: lazy(() => import('presentation/pages/auth/forgotPassword')),
  },
]
