import { useDispatch, useSelector } from 'react-redux'
import { Box, Card, CardContent, CardMedia } from '@mui/material'

import { ElementCarousel } from 'domain/models/carousel'
import { carouselOpen, carouselState } from 'presentation/redux/slices/carouselSlice'
import { ButtonApp, Carousel, TextParagraph, TextTitle } from 'presentation/components/elements'

type ProductCardPreviewProps = {
  cardContent: any
  elements: ElementCarousel[]
}

function Index({ cardContent, elements }: ProductCardPreviewProps) {
  const { isCarouselOpen } = useSelector(carouselState)
  const dispatch = useDispatch()

  const handleOpenCard = () => {
    const data = {
      elements,
      startPosition: 0,
    }
    dispatch(carouselOpen(data))
  }

  return (
    <>
      <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
        <CardMedia
          image={cardContent?.imageUrl}
          sx={{
            height: '300px',
            borderRadius: '30px',
          }}
        />
        <CardContent sx={{ paddingX: '0px' }}>
          <TextTitle fontSize="17.44px" color="text.secondary" lineHeight="20.44px">
            {cardContent?.title}
          </TextTitle>
          <Box sx={{ minHeight: '35px', margin: '10px 0' }}>
            <TextParagraph fontSize="12px" lineHeight="14px" color="text.secondary">
              {cardContent?.previewText}
            </TextParagraph>
          </Box>
          <ButtonApp height="40px" onClick={() => handleOpenCard()}>
            VER MÁS
          </ButtonApp>
        </CardContent>
      </Card>
      {isCarouselOpen && <Carousel />}
    </>
  )
}

export default Index
