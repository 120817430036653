import jwtDecode from 'jwt-decode'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { UserInfo } from 'domain/models/authUser'
import { Status } from 'domain/models/apiResponse'
import { store } from 'presentation/redux/store'
import { refreshTokenSuccess } from 'presentation/redux/slices/authUserSlice'
import { accessTokenKey } from 'presentation/constant/sessionStorageKeys'
import { RequestProps } from 'infraestructure/dataSources/http/types'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

export const handleRefreshToken = async (props: RequestProps): Promise<any> => {
  try {
    const { refreshToken } = store.getState().authUser
    const response = await AuthUserController.refreshToken(refreshToken)
    if (response?.status === Status.success) {
      const aToken = response?.data?.access_token as string
      const decodedToken: UserInfo = jwtDecode(aToken)
      const userData = {
        logged: true,
        refreshExpiresIn: response?.data?.refresh_expires_in as number,
        refreshToken: response?.data?.refresh_token as string,
        accessToken: aToken,
        userInfo: decodedToken,
        accessExpiresIn: response?.data?.access_expires_in as number,
      }
      const newData = {
        accessToken: aToken,
        accessExpiresIn: response?.data?.access_expires_in,
      }
      sessionStorage.setItem(accessTokenKey, JSON.stringify(newData))
      store.dispatch(refreshTokenSuccess(userData))
      return await sendRequest(props)
    }
  } catch (error) {
    sessionStorage.clear()
    window.location.replace(urlRoutes.public.auth.login.urlTo)
  }
}
