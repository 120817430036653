import { useDispatch, useSelector } from 'react-redux'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { ResetPasswordAuthUserCommandRequest } from 'domain/entities/authUser'
import { Status } from 'domain/models/apiResponse'
import {
  authUser,
  resetPasswordError,
  resetPasswordStart,
  resetPasswordSuccess,
} from 'presentation/redux/slices/authUserSlice'
import { useError } from 'presentation/hooks'

export const useResetPassword = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(authUser)
  const { getErrorMessage } = useError()

  const resetPassword = async (data: ResetPasswordAuthUserCommandRequest) => {
    dispatch(resetPasswordStart())
    try {
      const response = await AuthUserController.resetPassword(data)
      if (response?.status === Status.success) {
        dispatch(resetPasswordSuccess())
        return true
      }
    } catch (err) {
      dispatch(resetPasswordError(getErrorMessage({ error: err })))
      return false
    }
  }

  return { resetPassword, isLoading, error }
}
