import { useCallback } from 'react'

const useError = () => {
  const getErrorMessage = useCallback(({ error }: any) => {
    if (error?.error?.content?.message === 'Network Error') {
      return 'Lo sentimos, revise su conexión a Internet.'
    }
    if (error?.error?.content?.message === 'ERR_PROXY_CONNECTION_FAILED') {
      return 'Lo sentimos, revise su conexión a través del proxy.'
    }
    if (error?.error?.status.toString().includes(50)) {
      return 'Lo sentimos, se produjo un error en el servidor.'
    }
    if (error?.error?.status === 401) {
      return (
        error?.error?.content?.message ||
        'Lo sentimos, no tiene autorización para accede al recurso solicitado.'
      )
    }
    if (error?.error?.status === 400) {
      return error?.error?.content?.message || 'Lo sentimos, la petición es icorrecta.'
    }
    if (error?.error?.status === 412) {
      return error?.error?.content?.message || 'Lo sentimos, la petición es icorrecta.'
    }
    if (error?.error?.status === 404) {
      return 'Lo sentimos, no encontramos el recurso solicitado.'
    }
    if (error?.error?.status === 403) {
      return 'Lo sentimos, existen problemas de comunicación entre el origen de la petición y el sevidor.'
    }
    return JSON.stringify(error)
  }, [])

  return { getErrorMessage }
}

export default useError
