import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import './index.css'
import { Provider } from 'react-redux'
import { store } from 'presentation/redux/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'react-date-range/dist/styles.css' // main css file of react-date-range
import 'react-date-range/dist/theme/default.css' // theme css file of react-date-range

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || '0.5'),
  replaysSessionSampleRate: parseFloat(process.env.SENTRY_REPLAYS_SESSION_RATE || '0.1'),
  replaysOnErrorSampleRate: parseFloat(process.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0'),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
