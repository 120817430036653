/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit'
// MOCK DATA IMPORTS
const BEBIDAS_ALCOHOLICAS = require('presentation/assets/images/graduation/cards/bebidas-alcoholicas.png')
const BOCADILLOS = require('presentation/assets/images/graduation/cards/bocadillo.png')
const DECORACION = require('presentation/assets/images/graduation/cards/decoration.png')
const FOTO_VIDEO = require('presentation/assets/images/graduation/cards/foto_video.png')
const HORA_LOCA = require('presentation/assets/images/graduation/cards/hoara_loca.png')
const MENU = require('presentation/assets/images/graduation/cards/mennu.png')
const MUSICA = require('presentation/assets/images/graduation/cards/música.png')
const PLANNER = require('presentation/assets/images/graduation/cards/planer.png')
const UBICACION = require('presentation/assets/images/graduation/cards/ubication.png')

// MOCK DATA
const mockData = {
  location: [
    {
      id: 1,
      imageUrl: UBICACION,
      title: 'UBICACIÓN',
      previewText: 'El lugar hará que el evento sea inolvidable',
      detailViewText:
        '¡Asegura el éxito del evento con un magnífico espacio! Elije el lugar adecuado para llevar a cabo todo lo que has planeado. Es vital adaptar el espacio a las distintas necesidades y preferencias, garantizando que cada aspecto se coordine sin problemas.',
    },
  ],
  menu: [
    {
      id: 1,
      imageUrl: MENU,
      title: 'MENÚ',
      previewText: 'Elije un menú que se adapte al evento',
      detailViewText:
        '¡Haga que el evento sea una experiencia culinaria inolvidable con un delicioso menú! El equipo de chefs profesionales se asegurará de que cada bocado sea un deleite para todos los invitados. Ya sea un menú completo de cena o pequeños bocadillos, todo se puede adaptar de acuerdo a las preferencias. También existen opciones vegetarianas, veganas y sin gluten para asegurar que todos los invitados disfruten de la comida. ¡No se pierda la oportunidad de impresionar a todos con un delicioso menú!',
    },
  ],
  music: [
    {
      id: 1,
      imageUrl: MUSICA,
      title: 'MÚSICA',
      previewText: 'La música adecuada llevará la fiesta al siguiente nivel',
      detailViewText:
        '¡Llena de vida el evento con la música perfecta! Personal calificado creará una atmósfera inolvidable para que los invitados bailen toda la noche. Con una amplia selección de géneros musicales y canciones personalizadas, la música hará que el evento sea único y divertido. Desde una banda en vivo hasta un DJ, el talento adecuado hará que todos disfruten al máximo.',
    },
  ],
  crazyHour: [
    {
      id: 1,
      imageUrl: HORA_LOCA,
      title: 'HORA LOCA',
      previewText: 'Para momentos increíbles y divertidos',
      detailViewText:
        'No puede faltar un toque de diversión y alegría en el evento! Disfruta un momento fantástico con todos los invitados mientras bailan y se divierten con accesorios divertidos como sombreros, gafas y pelucas. El equipo de organizadores profesionales garantizará que todo se coordine sin problemas para crear una experiencia inolvidable.',
    },
  ],
  videoPhoto: [
    {
      id: 1,
      imageUrl: FOTO_VIDEO,
      title: 'FOTO & VIDEO',
      previewText: 'Recuerdos memorables que durarán para siempre',
      detailViewText:
        'Guarda los mejores momentos del evento para recordarlos por siempre. Para ello, es fundamental contratar los servicios de fotografía y video profesional con un equipo altamente capacitado, que tenga la experiencia necesaria para capturar cada detalle de manera creativa y emotiva, inmortalizando los momentos más especiales del evento ',
    },
  ],
  snacks: [
    {
      id: 1,
      imageUrl: BOCADILLOS,
      title: 'BOCADILLOS',
      previewText: 'Ofrece una experiencia deliciosa a todos los invitados',
      detailViewText:
        'Una deliciosa y conveniente opción de bocadillos es fundamental para el evento, para ello se requiere buscar proveedores que  utilicen ingredientes frescos y de alta calidad.',
    },
  ],
  alcolicDrinks: [
    {
      id: 1,
      imageUrl: BEBIDAS_ALCOHOLICAS,
      title: 'BEBIDAS ALCOHÓLICAS',
      previewText: 'Las mejores bebidas para acompañar el evento',
      detailViewText:
        'Disfruta una amplia selección de bebidas alcohólicas para hacer del evento una experiencia especial. Desde vinos y licores hasta cervezas artesanales y cócteles personalizados, existe todo tipo de productos para satisfacer los paladares más exigentes.',
    },
  ],
  planer: [
    {
      id: 1,
      imageUrl: PLANNER,
      title: 'PLANNER',
      previewText: 'El evento estará en las mejores manos',
      detailViewText:
        'Un Planner es la persona responsable de planificar, coordinar y ejecutar todo para el evento. Su trabajo incluye elegir la ubicación, seleccionar el menú, organizar la decoración, contratar músicos, asegurar que todo el equipo de producción esté coordinado y supervisar todo el evento en sí mismo. El objetivo principal del planner es asegurarse de que todo funcione sin problemas y de que los invitados disfruten de una experiencia inolvidable.',
    },
  ],
  decoration: [
    {
      id: 1,
      imageUrl: DECORACION,
      title: 'DECORACIÓN',
      previewText: 'Para un evento único y original',
      detailViewText:
        'Para que el evento sea impresionante y memorable, es fundamental seleccionar la decoración. Desde la iluminación hasta el mobiliario, existe una amplia variedad de opciones para hacer que tu evento sea único y especial, creando una experiencia mágica que perdure en la memoria por siempre.',
    },
  ],
}

const initialState = {
  isRequesting: false,
  graduationDataContent: mockData,
}
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const graduationSlice = createSlice({
  name: 'graduation',
  initialState,
  reducers: {
    graduationListRequest: (state) => {
      state.isRequesting = true
    },
    graduationListSuccess: (state, action) => {
      state.isRequesting = false
      state.graduationDataContent = { ...action.payload }
    },
    graduationListFail: (state) => {
      state.isRequesting = false
    },
  },
})

export const { graduationListRequest, graduationListSuccess, graduationListFail } =
  graduationSlice.actions
export default graduationSlice.reducer
