import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { EventMovementsState } from 'domain/models/eventMovements'
import { ReadEventMovementsQueryResponse } from 'domain/entities/eventMovements'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: EventMovementsState = {
  error: null,
  isLoading: false,
  eventMovements: [],
  pagination: PaginationEmpty,
}

export const eventMovementsSlice = createSlice({
  name: 'eventMovements',
  initialState,
  reducers: {
    getEventMovementsStart: (state: EventMovementsState) => {
      state.isLoading = true
      state.error = null
    },
    getEventMovementsSuccess: (
      state: EventMovementsState,
      action: PayloadAction<ReadEventMovementsQueryResponse>,
    ) => {
      state.isLoading = false
      state.eventMovements = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getEventMovementsError: (state: EventMovementsState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const eventMovementsState = (state: RootState) => state.eventMovements
export const { getEventMovementsSuccess, getEventMovementsStart, getEventMovementsError } =
  eventMovementsSlice.actions

export default eventMovementsSlice.reducer
