import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Home as HomeIcon, School as SchoolIcon } from '@mui/icons-material'
import { Box, MenuItem } from '@mui/material'

import { Student } from 'domain/entities/representative'
import { FilterOperator } from 'domain/models/filterOperator'
import { FilterValueType } from 'domain/models/filterValueType'
import { Query } from 'domain/models/query'
import { TextParagraph } from 'presentation/components/elements'
import {
  HeaderAccountUserNavItem,
  HeaderMenu,
  HeaderNotificationsNavItem,
  HeaderWrapperNavBarItems,
} from 'presentation/components/layout/components/Header/components'
import { StyledItemMenuText } from 'presentation/components/layout/components/Header/header.styled'
import {
  doNotRepresentStudentsYeat,
  mainMenuText,
  selectChildText,
  studentText,
} from 'presentation/constant/texts/header'
import EllipseIcon from 'presentation/assets/icons/ellipse.svg'
import { useHeader } from 'presentation/components/layout/components/Header/hooks'
import { deleteRepresentativeSelectedChild } from 'presentation/redux/slices'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { useNotifications } from 'presentation/redux/hooks/notifications'
import { notificationsState } from 'presentation/redux/slices/notificationSlice'
import { authUser } from 'presentation/redux/slices/authUserSlice'
import { NavItemPopup } from './components'

function HeaderNavItems() {
  const [isOpenBackdrop, setIsOpenBackdrop] = useState<boolean>(false)
  const {
    anchorStudentEl,
    anchorNotificationsEl,
    anchorUserEl,
    isOpenNotificationsMenu,
    isOpenStudentMenu,
    selectedItemMenu,
    handleClickListNotificationsItemMenu,
    handleCloseNotificationsMenu,
    handleCloseUserMenu,
    handleClickListStudentItemMenu,
    handleClickListUserItemMenu,
    isOpenUserMenu,
    handleClickItemMenu,
    handleCloseStudentMenu,
    students,
  } = useHeader()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notifications } = useSelector(notificationsState)
  const { userInfo } = useSelector(authUser)
  const { getNotifications, isLoading, error } = useNotifications()
  const handleGetNotifications = async () => {
    const filters = [
      `readed__${FilterOperator.equal}__${FilterValueType.bool}(${false})__AND`,
      `representativeId__${FilterOperator.equal}__${userInfo?.user_id}__AND`,
    ]
    const data: Query = {
      filters,
    }
    await getNotifications(data)
  }

  useEffect(() => {
    handleGetNotifications()
  }, [getNotifications])

  useEffect(() => {
    return () => {
      dispatch(deleteRepresentativeSelectedChild())
    }
  }, [dispatch])
  const handleOpenBackdrop = () => {
    setIsOpenBackdrop(!isOpenBackdrop)
  }
  const handleCloseBackdrop = () => {
    setIsOpenBackdrop(false)
    handleCloseNotificationsMenu()
  }

  const idsArray = {
    ids: notifications?.map((item: any) => item.notificationId),
  }

  const handleClickMainMenu = () => {
    navigate(urlRoutes.private.simulator.urlTo)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '12px',
        flexWrap: 'wrap',
      }}
    >
      <HeaderWrapperNavBarItems
        icon={<HomeIcon color="info" fontSize="large" />}
        isShowArrowDownIcon={false}
        handleOpenMenu={handleClickMainMenu}
      >
        <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
          {mainMenuText}
        </StyledItemMenuText>
      </HeaderWrapperNavBarItems>
      <HeaderWrapperNavBarItems
        icon={<SchoolIcon color="info" fontSize="large" />}
        handleOpenMenu={handleClickListStudentItemMenu}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
          }}
        >
          {selectedItemMenu ? (
            <>
              <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '18px' }}>
                {studentText}
              </StyledItemMenuText>
              <StyledItemMenuText fontWeight={500} sx={{ lineHeight: '18px' }}>
                {selectedItemMenu?.studentFullName}
              </StyledItemMenuText>
            </>
          ) : (
            <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
              {selectChildText}
            </StyledItemMenuText>
          )}
        </Box>
        <HeaderMenu
          anchorEl={anchorStudentEl}
          isOpenMenu={isOpenStudentMenu}
          handleCloseMenu={handleCloseStudentMenu}
        >
          {error && <TextParagraph>{error}</TextParagraph>}
          {students.length ? (
            students.map((student: Student) => (
              <MenuItem
                key={student?.studentId}
                onClick={(event) => handleClickItemMenu(event, student)}
                selected={student?.studentId === selectedItemMenu?.studentId}
              >
                {selectedItemMenu?.studentId === student?.studentId ? (
                  <img
                    alt={EllipseIcon}
                    width={6}
                    src={EllipseIcon}
                    style={{ marginRight: '10px' }}
                  />
                ) : (
                  ''
                )}
                <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
                  {student?.studentFullName}
                </StyledItemMenuText>
              </MenuItem>
            ))
          ) : (
            <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
              {doNotRepresentStudentsYeat}
            </StyledItemMenuText>
          )}
        </HeaderMenu>
      </HeaderWrapperNavBarItems>
      <HeaderNotificationsNavItem
        handleClickListNotificationsItemMenu={handleClickListNotificationsItemMenu}
        handleCloseNotificationsMenu={handleCloseNotificationsMenu}
        isOpenNotificationsMenu={isOpenNotificationsMenu}
        anchorNotificationsEl={anchorNotificationsEl}
        handleOpenBackdrop={handleOpenBackdrop}
        notifications={notifications}
        error={error as string}
      />
      <HeaderAccountUserNavItem
        isOpenUserMenu={isOpenUserMenu}
        handleClickListUserItemMenu={handleClickListUserItemMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        anchorUserEl={anchorUserEl}
      />
      {isOpenBackdrop && (
        <NavItemPopup
          openBackdrop={isOpenBackdrop}
          handleCloseBackdrop={handleCloseBackdrop}
          idsArray={idsArray}
          handleCloseNotificationsMenu={handleCloseNotificationsMenu}
          handleNotifications={handleGetNotifications}
          isLoading={isLoading}
        />
      )}
    </Box>
  )
}

export default memo(HeaderNavItems)
