import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledBox = styled(Box)(
  ({ theme, padding }: { theme: any; padding: string | undefined }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    padding: padding || '40px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    maxHeight: '900px',
    overflow: 'auto',
    scrollbarWidth: 'none',

    '::-webkit-scrollbar': {
      width: '8px',
      background: theme.mixins.scrollBar.color,
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.info.main,
      borderRadius: '10px',
    },
  }),
)
