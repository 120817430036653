import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Student } from 'domain/entities/representative'
import {
  representativeState,
  selectedRepresentativeChild,
} from 'presentation/redux/slices/representativeSlice'
import useSessionStorage from 'presentation/hooks/useSessionStorage'
import { selectedChildKey } from 'presentation/constant/sessionStorageKeys'

export const useSaveSelectedStudentInSessionStorage = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(representativeState)
  const { saveDataAtSessionStorage } = useSessionStorage(selectedChildKey)

  const saveSelectedStudentInSessionStorage = useCallback(
    (child: Student) => {
      saveDataAtSessionStorage({ data: child })
      dispatch(selectedRepresentativeChild(child))
    },
    [dispatch, saveDataAtSessionStorage],
  )

  return { saveSelectedStudentInSessionStorage, isLoading, error }
}
