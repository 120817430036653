import React, { ReactNode } from 'react'
import { Box, Fade, FormControl, Typography } from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'

import { InputLabelApp, StyledTooltip } from 'presentation/styles/index.styled'

type FormControlAppProps = {
  label?: string
  errorMessage?: string | any
  isRequired?: boolean
  noGutter?: boolean
  children: ReactNode
  isDisabled?: boolean
  isShowTooltip?: boolean
  messageTooltip?: string
}

export function FormControlApp({
  label,
  errorMessage = '',
  isRequired,
  noGutter,
  children,
  isDisabled,
  isShowTooltip = false,
  messageTooltip = '',
}: FormControlAppProps) {
  return (
    <FormControl fullWidth variant="standard">
      {label && (
        <InputLabelApp
          required={isRequired}
          error={!!errorMessage}
          shrink
          style={{ color: 'primary' }}
          disabled={isDisabled}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {label}
            {isShowTooltip && (
              <StyledTooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={messageTooltip}
              >
                <InfoOutlinedIcon color="info" />
              </StyledTooltip>
            )}
          </Box>
        </InputLabelApp>
      )}
      {children}
      {!noGutter && (
        <Typography variant="caption" color="error">
          {errorMessage}
          {'\u00A0'}
        </Typography>
      )}
    </FormControl>
  )
}
