import { styled } from '@mui/material/styles'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { ExpandMore } from '@mui/icons-material'

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 9,
    top: -1,
    position: 'absolute',
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: '1px solid',
  },
}))

export const ExpandMoreIcon = styled(ExpandMore)(() => ({
  '&.MuiSvgIcon-root': {
    width: '26px',
    height: '26px',
  },
}))
