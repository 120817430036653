import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

export const UpdateData = [
  {
    nameUrl: 'Update Data',
    path: urlRoutes.private.updateData.url,
    element: lazy(() => import('presentation/pages/dataVerification')),
    rols: ['*'],
  },
]
