import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { accessTokenKey, userKey } from 'presentation/constant/sessionStorageKeys'
import {
  AuthUserState,
  PayloadLoginAndRefreshTokenAction,
  TokenLocalStorage,
  UserInfo,
  UserInfoEmpty,
  UserLocalStorage,
} from 'domain/models/authUser'
import { RecoverPasswordAuthUserCommandResponse } from 'domain/entities/authUser'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const user: UserLocalStorage = JSON.parse(sessionStorage.getItem(userKey) || '{}')
const token: TokenLocalStorage = JSON.parse(sessionStorage.getItem(accessTokenKey) || '{}')

const initialState: AuthUserState = {
  error: null,
  isLoading: false,
  logged: user?.logged,
  userInfo: user?.userInfo,
  accessToken: token.accessToken,
  refreshToken: user?.refreshToken,
  accessExpiresIn: token?.accessExpiresIn,
  refreshExpiresIn: user?.refreshExpiresIn,
  userForgotPassword: null,
}
export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    loginStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
    },
    loginSuccess: (
      state: AuthUserState,
      action: PayloadAction<PayloadLoginAndRefreshTokenAction>,
    ) => {
      state.isLoading = false
      state.logged = action.payload.logged
      state.userInfo = action.payload.userInfo
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.accessExpiresIn = action.payload.accessExpiresIn
      state.refreshExpiresIn = action.payload.refreshExpiresIn
    },
    loginError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    recoverPasswordStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
      state.userForgotPassword = null
    },
    recoverPasswordSuccess: (
      state: AuthUserState,
      action: PayloadAction<RecoverPasswordAuthUserCommandResponse>,
    ) => {
      state.isLoading = false
      state.userForgotPassword = action.payload
    },
    recoverPasswordError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    changePasswordStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
    },
    changePasswordSuccess: (state: AuthUserState) => {
      state.isLoading = false
      state.userInfo = { ...state.userInfo, ...{ firstLogin: false } }
    },
    changePasswordError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    refreshTokenSuccess: (
      state: AuthUserState,
      action: PayloadAction<PayloadLoginAndRefreshTokenAction>,
    ) => {
      state.logged = action.payload.logged
      state.userInfo = action.payload.userInfo
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      state.accessExpiresIn = action.payload.accessExpiresIn
      state.refreshExpiresIn = action.payload.refreshExpiresIn
    },
    logoutStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
    },
    logoutSuccess: (state: AuthUserState) => {
      state.isLoading = false
      state.logged = false
      state.userInfo = UserInfoEmpty
      state.accessToken = ''
      state.refreshToken = ''
      state.accessExpiresIn = 0
      state.refreshExpiresIn = 0
      state.error = null
    },
    logoutError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    clearError: (state: AuthUserState) => {
      state.error = null
    },
    resetPasswordStart: (state: AuthUserState) => {
      state.isLoading = true
      state.error = null
    },
    resetPasswordSuccess: (state: AuthUserState) => {
      state.isLoading = false
    },
    resetPasswordError: (state: AuthUserState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateUserInfo: (state: AuthUserState, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload
    },
  },
})

export const authUser = (state: RootState) => state.authUser
export const {
  loginStart,
  loginSuccess,
  loginError,
  recoverPasswordStart,
  recoverPasswordError,
  recoverPasswordSuccess,
  refreshTokenSuccess,
  changePasswordSuccess,
  changePasswordStart,
  changePasswordError,
  logoutSuccess,
  logoutStart,
  logoutError,
  clearError,
  resetPasswordError,
  resetPasswordSuccess,
  resetPasswordStart,
  updateUserInfo,
} = authUserSlice.actions

export default authUserSlice.reducer
