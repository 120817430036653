import { useDispatch, useSelector } from 'react-redux'

import { authUser, clearError } from 'presentation/redux/slices/authUserSlice'

export const useClearErrorAuth = () => {
  const dispatch = useDispatch()
  const { error } = useSelector(authUser)

  const clearErrorAuth = async () => {
    dispatch(clearError())
  }

  return { clearErrorAuth, error }
}
