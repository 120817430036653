import { Box } from '@mui/material'

import { MarkNotificationsAsReadCommandRequest } from 'domain/entities/notifications'
import {
  BackdropPopup,
  ButtonApp,
  LoadingBackdrop,
  TextBold,
} from 'presentation/components/elements'
import { changeNotificationsReading } from 'presentation/constant/texts/notification'
import { useMarkNotificationsAsRead } from 'presentation/redux/hooks/notifications'
import { cancelText, continueText } from 'presentation/constant/globals'

type RecoverPasswordPopupProps = {
  openBackdrop: boolean
  handleCloseBackdrop: () => void
  idsArray: MarkNotificationsAsReadCommandRequest
  handleCloseNotificationsMenu: () => void
  handleNotifications: () => void
  isLoading: boolean
}

export default function NavItemPopup({
  openBackdrop,
  handleCloseBackdrop,
  handleCloseNotificationsMenu,
  idsArray,
  handleNotifications,
  isLoading,
}: RecoverPasswordPopupProps) {
  const { markNotificationsAsRead } = useMarkNotificationsAsRead()
  const handleNotificationRead = async () => {
    await markNotificationsAsRead(idsArray)
    handleCloseNotificationsMenu()
    handleCloseBackdrop()
    handleNotifications()
  }

  return (
    <BackdropPopup openBackdrop={openBackdrop}>
      {isLoading && <LoadingBackdrop />}
      <Box sx={{ textAlign: 'center', width: '700px' }}>
        <TextBold fontSize="24px" lineHeight="30px" color="info.main">
          {changeNotificationsReading}
        </TextBold>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          marginTop: '40px',
        }}
      >
        <ButtonApp isOutlined onClick={handleCloseBackdrop}>
          {cancelText}
        </ButtonApp>
        <ButtonApp isSubmitType onClick={handleNotificationRead}>
          {continueText}
        </ButtonApp>
      </Box>
    </BackdropPopup>
  )
}
