import { ApiResponse } from 'domain/models/apiResponse'

export function makeResponse<T>(response: any): ApiResponse<T> {
  let result: ApiResponse<T>
  if (response?.data != null && typeof response?.data !== 'undefined') {
    result = {
      data: response?.data === '' ? null : response?.data,
      status: response?.status,
    }
  } else {
    result = {
      data: response?.data === '' ? null : response,
      status: response?.status,
    }
  }
  return result
}
