import { MouseEventHandler } from 'react'
import { Box, List, ListItem, ListItemText, useTheme } from '@mui/material'
import { Notifications as NotificationIcon } from '@mui/icons-material'

import { Notification } from 'domain/entities/notifications'
import { AlertError, ButtonApp, TextBold } from 'presentation/components/elements'
import { StyledItemMenuText } from 'presentation/components/layout/components/Header/header.styled'
import {
  HeaderMenu,
  HeaderWrapperNavBarItems,
} from 'presentation/components/layout/components/Header/components'
import { notificationsText } from 'presentation/constant/texts/header'
import EllipseBlueIcon from 'presentation/assets/icons/ellipse-blue.svg'
import { readAllNotifications, youDontHaveNew } from 'presentation/constant/texts/notification'

type HeaderNotificationsNavItemProps = {
  isOpenNotificationsMenu: boolean
  handleClickListNotificationsItemMenu: MouseEventHandler<HTMLElement>
  handleCloseNotificationsMenu: () => void
  anchorNotificationsEl: any
  handleOpenBackdrop: () => void
  notifications: Notification[]
  error: string
}

export default function HeaderNotificationsNavItem({
  isOpenNotificationsMenu,
  handleClickListNotificationsItemMenu,
  handleCloseNotificationsMenu,
  anchorNotificationsEl,
  handleOpenBackdrop,
  notifications,
  error,
}: HeaderNotificationsNavItemProps) {
  const theme = useTheme()

  return (
    <HeaderWrapperNavBarItems
      icon={<NotificationIcon color="info" fontSize="large" />}
      handleOpenMenu={handleClickListNotificationsItemMenu}
      isBadge={notifications.length > 0}
      badgeInfo={notifications.length}
    >
      <StyledItemMenuText fontWeight={300} sx={{ lineHeight: '30px' }}>
        {notificationsText}
      </StyledItemMenuText>
      <HeaderMenu
        anchorEl={anchorNotificationsEl}
        isOpenMenu={isOpenNotificationsMenu}
        handleCloseMenu={handleCloseNotificationsMenu}
        isUseGap
      >
        {error && <AlertError text={error} />}
        {notifications.length ? (
          <>
            <TextBold color="info.main" fontSize="16px">
              {notificationsText}
            </TextBold>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                position: 'relative',
                overflow: 'auto',
                maxHeight: 300,
                '::-webkit-scrollbar': {
                  width: '6px',
                  background: theme.mixins.scrollBar.color,
                  borderRadius: '5px',
                  height: '105px',
                },
                '::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '::-webkit-scrollbar-thumb': {
                  background: theme.palette.info.main,
                  borderRadius: '10px',
                  height: 'auto',
                },
              }}
            >
              {notifications?.map((notification: any) => (
                <ListItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '18px',
                    width: '100%',
                  }}
                  key={notification?.notificationId}
                  disableGutters
                  secondaryAction={
                    <img alt={EllipseBlueIcon} src={EllipseBlueIcon} style={{ margin: '20px' }} />
                  }
                >
                  <ListItemText
                    primary={`${notification?.message}`}
                    sx={{ marginTop: 0, marginBottom: 0 }}
                  />
                </ListItem>
              ))}
            </List>
            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
              <ButtonApp onClick={handleOpenBackdrop} height="40px" paddingX="20px">
                {readAllNotifications}
              </ButtonApp>
            </Box>
          </>
        ) : (
          <TextBold color="info.main" fontSize="16px">
            {youDontHaveNew}
          </TextBold>
        )}
      </HeaderMenu>
    </HeaderWrapperNavBarItems>
  )
}
