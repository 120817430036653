import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const ContainerBox = styled(Box)(({ theme }) => ({
  zIndex: '1',
  width: '100%',
  color: theme.palette.background.default,
}))
export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}))

export const StyledLinkTermsConditionsText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 300,
  lineHeight: '14.06px',
  color: theme.palette.text.primary,
}))

export const StyledLinkText = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 300,
  lineHeight: '24px',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.info.light,
  },
}))
