import { RecoverPasswordAuthUserCommandResponse } from 'domain/entities/authUser'

export const UserInfoEmpty = {
  exp: 0,
  firstLogin: false,
  fullName: '',
  iat: 0,
  show_privacyPolicy_front_office: false,
  show_termsAndConditions_front_office: false,
  user_id: '',
}

export type UserInfo = {
  exp: number
  firstLogin: boolean
  fullName: string
  iat: number
  show_privacyPolicy_front_office: boolean
  show_termsAndConditions_front_office: boolean
  user_id: string
}

export type UserLocalStorage = {
  logged: boolean
  refreshToken: string
  userInfo: UserInfo
  refreshExpiresIn: number
}

export type TokenLocalStorage = {
  accessToken: string
  accessExpiresIn: number
}

export type PayloadLoginAndRefreshTokenAction = {
  logged: boolean
  userInfo: UserInfo
  accessToken: string
  refreshToken: string
  refreshExpiresIn: number
  accessExpiresIn: number
}

export type AuthUserState = {
  error: string | null
  isLoading: boolean
  logged: boolean
  userInfo: UserInfo
  accessToken: string
  refreshToken: string
  refreshExpiresIn: number
  accessExpiresIn: number
  userForgotPassword: RecoverPasswordAuthUserCommandResponse | null
}
