import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { carouselClose, carouselState } from 'presentation/redux/slices/carouselSlice'
import CardComponent from '../productDetailViewCard'
import { StyledBackdrop } from './index.styled'

function Carousel() {
  const [position, setPosition] = useState(0)
  const dispatch = useDispatch()
  const { startPosition, elements, isCarouselOpen } = useSelector(carouselState)

  useEffect(() => {
    setPosition(startPosition)
  }, [startPosition])

  const handleCloseCarousel = () => {
    dispatch(carouselClose())
  }

  const handleNext = () => {
    setPosition(position === elements.length - 1 ? 0 : position + 1)
  }
  const handlePrev = () => {
    setPosition(position === 0 ? elements.length - 1 : position - 1)
  }
  return (
    <StyledBackdrop open={isCarouselOpen}>
      <CardComponent
        cardContent={elements[position]}
        handleCloseCarousel={handleCloseCarousel}
        handleNext={handleNext}
        handlePrev={handlePrev}
        onlyOne={elements.length === 1}
        isFirstElement={position === 0}
        isLastElement={position === elements.length - 1}
      />
    </StyledBackdrop>
  )
}

export default Carousel
