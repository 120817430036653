import { styled } from '@mui/material/styles'
import { Divider, Typography } from '@mui/material'

export const StyledItemMenuText = styled(Typography)(() => ({
  fontSize: '16px',
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  border: `1px solid ${theme.palette.background.default}`,
}))
