import { Grid } from '@mui/material'

import { LogoComponent, TextSubTitle } from 'presentation/components/elements'
import { dinersBankCards } from 'presentation/constant/texts/generalBackgroundHistory'
import { LOGOS } from 'presentation/constant/icons/logos'

function CardsLogosContainer() {
  return (
    <Grid container justifyContent="center" mt="1em" direction="column" alignItems="center">
      <Grid item xs={12} display="flex" flexDirection="row" justifyContent="center" gap={2}>
        <LogoComponent src={LOGOS.DINERS} />
        <LogoComponent src={LOGOS.TITANIUM} />
        <LogoComponent src={LOGOS.DISCOVER} />
      </Grid>
      <LogoComponent src={LOGOS.PLACE_TO_PAY} />
      <Grid mt={2}>
        <TextSubTitle fontSize="10px" color="secondary.main">
          {dinersBankCards}
        </TextSubTitle>
      </Grid>
    </Grid>
  )
}

export default CardsLogosContainer
