import { ReactNode } from 'react'

import { Button, IconButton, Tooltip, useTheme } from '@mui/material'

type ButtonTextAppProps = {
  onClick?: any
  children: ReactNode
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'inherit'
  startIcon?: any
  endIcon?: any
  size?: 'small' | 'medium' | 'large'
  padding?: string
}

export function ButtonTextApp({
  onClick,
  children,
  color = 'inherit',
  startIcon,
  endIcon,
  size,
  padding,
}: ButtonTextAppProps) {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      color={color}
      variant="text"
      size={size}
      sx={{
        fontWeight: 300,
        textTransform: 'inherit',
        '&:hover': {
          color: theme.palette.info.main,
        },
        padding,
      }}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </Button>
  )
}

type ButtonAppProps = {
  isSecondary?: boolean
  isOutlined?: boolean
  isDisabled?: boolean
  isSubmitType?: boolean
  isFullWidth?: boolean
  children: ReactNode
  startIcon?: any
  endIcon?: any
  paddingX?: string
  onClick?: any
  size?: 'small' | 'medium' | 'large'
  height?: string
}

export function ButtonApp({
  isSecondary,
  isOutlined,
  isDisabled,
  isSubmitType,
  height,
  isFullWidth,
  paddingX,
  onClick,
  children,
  startIcon = null,
  endIcon = null,
  size = 'medium',
}: ButtonAppProps) {
  return (
    <Button
      color={isSecondary ? 'inherit' : 'info'}
      size={size}
      variant={isOutlined ? 'outlined' : 'contained'}
      disabled={isDisabled}
      type={isSubmitType ? 'submit' : 'button'}
      fullWidth={isFullWidth}
      sx={{
        paddingX: `${!isFullWidth && paddingX ? paddingX : '41.7px'}`,
        height: height || '50px',
        borderRadius: '8px',
      }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

type ButtonIconAppProps = {
  titleTooltip: string
  color?: 'primary' | 'secondary' | 'inherit'
  onClick?: () => void
  Icon?: any
}

export function ButtonIconApp({
  titleTooltip,
  color = 'primary',
  onClick,
  Icon,
}: ButtonIconAppProps) {
  return (
    <Tooltip title={titleTooltip}>
      <IconButton color={color} size="small" onClick={onClick}>
        <Icon size="small" />
      </IconButton>
    </Tooltip>
  )
}
