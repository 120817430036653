import {
  FirstChangePasswordAuthUserCommandRequest,
  FirstChangePasswordAuthUserCommandResponse,
  LoginAuthUserCommandRequest,
  LoginAuthUserCommandResponse,
  LogoutAuthUserCommandResponse,
  RecoverPasswordAuthUserCommandResponse,
  ResetPasswordAuthUserCommandRequest,
  ResetPasswordAuthUserCommandResponse,
} from 'domain/entities/authUser'
import { sendRequest } from 'infraestructure/dataSources/http/axios'
import { AsyncApiResponse } from 'domain/models/apiResponse'
import IAuthUserRepository from 'domain/interfaces/repositories/IAuthUserRepository'
import {
  FIRST_CHANGE_PASSWORD_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  RECOVER_PASSWORD_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
} from './endpoints.routes'

export default class AuthUserRepository implements IAuthUserRepository {
  async login(data: LoginAuthUserCommandRequest): AsyncApiResponse<LoginAuthUserCommandResponse> {
    const request = {
      post: {
        path: LOGIN_ENDPOINT,
        body: data,
      },
    }
    return await sendRequest<LoginAuthUserCommandResponse>(request)
  }

  async logout(token: string): AsyncApiResponse<LogoutAuthUserCommandResponse> {
    const request = {
      post: {
        path: LOGOUT_ENDPOINT,
        body: { refreshToken: token },
      },
    }
    return await sendRequest<LogoutAuthUserCommandResponse>(request)
  }

  async refreshToken(token: string): AsyncApiResponse<LoginAuthUserCommandResponse> {
    const request = {
      post: {
        path: REFRESH_TOKEN_ENDPOINT,
        body: { refreshToken: token },
      },
    }
    return await sendRequest<LoginAuthUserCommandResponse>(request)
  }

  async recoverPassword(
    identificationNumber: string,
  ): AsyncApiResponse<RecoverPasswordAuthUserCommandResponse> {
    const request = {
      post: {
        path: `${RECOVER_PASSWORD_ENDPOINT}/${identificationNumber}`,
        body: {},
      },
    }
    return await sendRequest<RecoverPasswordAuthUserCommandResponse>(request)
  }

  async firstChangePassword(
    data: FirstChangePasswordAuthUserCommandRequest,
  ): AsyncApiResponse<FirstChangePasswordAuthUserCommandResponse> {
    const request = {
      post: {
        path: FIRST_CHANGE_PASSWORD_ENDPOINT,
        body: data,
      },
    }
    return await sendRequest<FirstChangePasswordAuthUserCommandResponse>(request)
  }

  async resetPassword(
    data: ResetPasswordAuthUserCommandRequest,
  ): AsyncApiResponse<ResetPasswordAuthUserCommandResponse> {
    const request = {
      post: {
        path: `${RESET_PASSWORD_ENDPOINT}/${data.token}`,
        body: data,
      },
    }
    return await sendRequest<ResetPasswordAuthUserCommandResponse>(request)
  }
}
