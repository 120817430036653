import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { UpdateData } from 'presentation/routes/privatePages/updateDataRoutes/updateData'
import { privateSimulatorRoutes } from './privateSimulatorRoutes/privateSimulatorRoutes'
import { generalBackgroundHistoryRoutes } from './generalBackgroundHistoryRoutes/generalBackgroundHistoryRoutes'
import { AuthRoutes } from './authRoutes/authRoutes'
import { TravelVoteRoutes } from './travelVote/travelVoteRoutes'

const NotFound = lazy(() => import('presentation/pages/notFound404'))

export const privateRoutes = [
  ...privateSimulatorRoutes,
  ...generalBackgroundHistoryRoutes,
  ...AuthRoutes,
  ...UpdateData,
  ...TravelVoteRoutes,
]

type PrivatePagesProps = {
  rol: string
}

export default function PrivatePages({ rol }: PrivatePagesProps) {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {privateRoutes.map((routes: any) => {
        if (routes.rols.find((role: any) => role === rol || role === '*')) {
          return <Route key={routes.path} path={routes.path} element={<routes.element />} />
        }
        return null
      })}
    </Routes>
  )
}
