import { Box } from '@mui/material'

type LogoProps = {
  src: string
  alt?: string
}

function Logo({ src, alt }: LogoProps) {
  return (
    <Box display="flex" width="fit-content" justifyContent="center" alignItems="center">
      <img src={src} alt={alt ?? ''} style={{ minWidth: '80px' }} />
    </Box>
  )
}

export default Logo
