import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { LoginAuthUserCommandRequest } from 'domain/entities/authUser'
import { UserInfo } from 'domain/models/authUser'
import { Status } from 'domain/models/apiResponse'
import { accessTokenKey, userKey } from 'presentation/constant/sessionStorageKeys'
import {
  authUser,
  loginError,
  loginStart,
  loginSuccess,
} from 'presentation/redux/slices/authUserSlice'
import { useError, useSessionStorage } from 'presentation/hooks'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

export const useLogin = () => {
  const [isOpenNotSignedChildrenBackdrop, setIsOpenNotSignedChildrenBackdrop] =
    useState<boolean>(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading, error } = useSelector(authUser)
  const { saveDataAtSessionStorage } = useSessionStorage(userKey)
  const { getErrorMessage } = useError()

  const handleToggleNotSignedChildrenBackdrop = () => {
    setIsOpenNotSignedChildrenBackdrop(!isOpenNotSignedChildrenBackdrop)
  }

  const login = async (data: LoginAuthUserCommandRequest) => {
    dispatch(loginStart())
    try {
      const response = await AuthUserController.login(data)
      if (response?.status === Status.success) {
        const token = response?.data?.access_token as string
        const decodedToken: UserInfo = jwtDecode(token as string)
        const userData = {
          logged: true,
          refreshExpiresIn: response?.data?.refresh_expires_in as number,
          refreshToken: response?.data?.refresh_token as string,
          userInfo: decodedToken,
        }
        saveDataAtSessionStorage({ data: userData })
        sessionStorage.setItem(
          accessTokenKey,
          JSON.stringify({
            accessToken: token,
            accessExpiresIn: response?.data?.access_expires_in,
          }),
        )
        const payload = {
          ...userData,
          accessToken: token,
          accessExpiresIn: response?.data?.access_expires_in as number,
        }
        dispatch(loginSuccess(payload))
        const redirectRoute = () => {
          if (decodedToken?.firstLogin) {
            return urlRoutes.public.auth.createPassword.urlTo
          }
          return urlRoutes.private.simulator.urlTo
        }
        navigate(redirectRoute())
      }
    } catch (err: any) {
      if (err?.error?.status === 412) {
        handleToggleNotSignedChildrenBackdrop()
      }
      dispatch(loginError(getErrorMessage({ error: err })))
    }
  }

  return {
    login,
    isLoading,
    error,
    isOpenNotSignedChildrenBackdrop,
    handleToggleNotSignedChildrenBackdrop,
  }
}
