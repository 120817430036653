import { useDispatch, useSelector } from 'react-redux'

import { AuthUserController } from 'infraestructure/controllers/authUserController'
import { Status } from 'domain/models/apiResponse'
import { RecoverPasswordAuthUserCommandResponse } from 'domain/entities/authUser'
import {
  authUser,
  recoverPasswordError,
  recoverPasswordStart,
  recoverPasswordSuccess,
} from 'presentation/redux/slices/authUserSlice'
import { useError } from 'presentation/hooks'

export const useRecoverPassword = () => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector(authUser)
  const { getErrorMessage } = useError()

  const recoverPassword = async (id: string) => {
    dispatch(recoverPasswordStart())
    try {
      const response = await AuthUserController.recoverPassword(id)
      if (response?.status === Status.success) {
        dispatch(recoverPasswordSuccess(response.data as RecoverPasswordAuthUserCommandResponse))
        return true
      }
    } catch (err) {
      dispatch(recoverPasswordError(getErrorMessage({ error: err })))
      return false
    }
  }

  return { recoverPassword, isLoading, error }
}
