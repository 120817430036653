import IRepresentativeRepository from 'domain/interfaces/repositories/IRepresentativeRepository'
import { UpdateRepresentativeCommandRequest } from 'domain/entities/representative'

export const updateRepresentativeCommand = (
  repository: IRepresentativeRepository,
  representativeId: string,
  data: UpdateRepresentativeCommandRequest,
) => {
  return repository.update(representativeId, data)
}
