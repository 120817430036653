import {
  FirstChangePasswordAuthUserCommandRequest,
  LoginAuthUserCommandRequest,
  ResetPasswordAuthUserCommandRequest,
} from 'domain/entities/authUser'
import AuthUserRepository from 'infraestructure/repositories/authUserRepository'
import {
  firstChangePasswordAuthUserCommand,
  loginAuthUserCommand,
  logoutAuthUserCommand,
  recoverPasswordAuthUserCommand,
  refreshTokenAuthUserCommand,
  resetPasswordAuthUserCommand,
} from 'application/commands/authUser'

const authUserRepository = new AuthUserRepository()

export class AuthUserController {
  static async login(data: LoginAuthUserCommandRequest) {
    return await loginAuthUserCommand(authUserRepository, data)
  }

  static async logout(token: string) {
    return await logoutAuthUserCommand(authUserRepository, token)
  }

  static async recoverPassword(identificationNumber: string) {
    return await recoverPasswordAuthUserCommand(authUserRepository, identificationNumber)
  }

  static async firstChangePassword(data: FirstChangePasswordAuthUserCommandRequest) {
    return await firstChangePasswordAuthUserCommand(authUserRepository, data)
  }

  static async refreshToken(token: string) {
    return await refreshTokenAuthUserCommand(authUserRepository, token)
  }

  static async resetPassword(data: ResetPasswordAuthUserCommandRequest) {
    return await resetPasswordAuthUserCommand(authUserRepository, data)
  }
}
