import { lazy } from 'react'

import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'

const GeneralBackgroundHistory = lazy(() => import('presentation/pages/generalBackgroundHistory'))

export const generalBackgroundHistoryRoutes = [
  {
    nameUrl: 'Historial de Fondo General',
    path: urlRoutes.private.generalBackgroundHistory.url,
    element: GeneralBackgroundHistory,
    rols: ['*'],
  },
]
