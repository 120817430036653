import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import WestIcon from '@mui/icons-material/West'
import { Box, IconButton, useTheme } from '@mui/material'

import { TextParagraph, TextTitle } from 'presentation/components/elements'
import { urlRoutes } from 'presentation/routes/config/urlRoutes/urlRoutes'
import { soonYouWillBeVoteForText } from 'presentation/constant/texts/graduationParty'
import { soonYouWillBeVoteForTravelText, travelText } from 'presentation/constant/texts/travel'
import { HeadTextContainerBox } from './index.styles'

type ProductDisplayProps = {
  title: string
  subtitle: string
  background: string
  children: ReactNode | ReactNode[]
}

function ProductDisplay({ title, subtitle, background, children }: ProductDisplayProps) {
  const theme = useTheme()

  return (
    <Box width="100%" height="100%">
      <Box
        sx={{
          background: `linear-gradient(180deg, rgba(26, 26, 26, 0) 53.25%, #171717 93.23%), 
  linear-gradient(270deg, rgba(28, 19, 8, 0.3) 23.42%, rgba(28, 19, 8, 0) 47.32%), url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '720px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              maxWidth: '1700px',
              width: '100%',
            }}
          >
            <HeadTextContainerBox>
              <Link to={urlRoutes.public.home.url}>
                <IconButton>
                  <WestIcon fontSize="large" style={{ color: 'white' }} />
                </IconButton>
              </Link>
              <TextTitle fontSize="44px" lineHeight="51.56px" color="text.secondary">
                {title.toUpperCase()}
              </TextTitle>
              <TextParagraph fontSize="18px" lineHeight="28px" color="text.secondary">
                {subtitle}
              </TextParagraph>
            </HeadTextContainerBox>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: theme.mixins.blackDark.color,
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '47px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '1700px',
            width: '100%',
            gap: '47px',
          }}
        >
          {children}
          {title === `${travelText}` ? (
            <TextTitle fontSize="24px" lineHeight="28.13px" color="text.secondary">
              {soonYouWillBeVoteForTravelText}
            </TextTitle>
          ) : (
            <TextTitle fontSize="24px" lineHeight="28.13px" color="text.secondary">
              {soonYouWillBeVoteForText}
            </TextTitle>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ProductDisplay
