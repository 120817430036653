import { StudentPaymentResponse } from 'domain/entities/student'

export type PaymentSession = {
  status: string
  value: number
  reference: string
  description: string
  reason: string
  authorization: string
  paymentDate: string
}
export const paymentSessionEmpty = {
  status: '',
  value: 0,
  reference: '',
  description: '',
  reason: '',
  authorization: '',
  paymentDate: '',
}

export const StatusText = {
  accepted: 'Aprobada',
  rejected: 'Rechazada',
  pending: 'Pendiente',
}

export type StudentState = {
  error: string | null
  errorVotingInfo: string | null
  isLoading: boolean
  paymentSession: PaymentSession
  student: StudentPaymentResponse | null
  isLoadingUpload: boolean
  votedForTravel: boolean
}
