import { styled } from '@mui/material/styles'
import { Box, InputBase } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  flexWrap: 'wrap',
}))

export const ContainerBoxFormControl = styled(Box)(() => ({
  width: '198px',
}))

export const StyledDataPicker = styled(DatePicker)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiOutlinedInput-input': {
    padding: '7px 14px',
    textAlign: 'center',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    border: `1px solid ${theme.palette.info.main}`,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiDayCalendar-weekDayLabel': {
    color: theme.palette.primary.main,
  },
}))

export const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.4),
  },
  '& .MuiInputBase-input': {
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.info.light}`,
    color: theme.palette.secondary.light,
    fontSize: '12px',
    width: '100%',
    padding: '9.375px 10px 9.375px 40px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      border: `1px solid ${theme.palette.info.light}`,
    },
    '&:disabled': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))
