export const CORE_API_BASE = `${process.env.REACT_APP_CORE_API_HOST}`
export const STUDENTS = 'students'
export const REPRESENTATIVES = 'representatives'
const FRONT_OFFICE = 'frontoffice'
const AUTH = 'auth'
const PASSWORDS = 'passwords'
const PLACE_TO_PAY = 'place-to-pay'

export const TRAVEL_PACKAGES = 'travel-packages'
// endpoints to representative
export const LOGIN_ENDPOINT = `${CORE_API_BASE}/${FRONT_OFFICE}/${AUTH}/login`
export const LOGOUT_ENDPOINT = `${CORE_API_BASE}/${FRONT_OFFICE}/${AUTH}/logout`
export const FIRST_CHANGE_PASSWORD_ENDPOINT = `${CORE_API_BASE}/${FRONT_OFFICE}/${AUTH}/${PASSWORDS}/first-change`
export const RESET_PASSWORD_ENDPOINT = `${CORE_API_BASE}/${REPRESENTATIVES}/${PASSWORDS}/reset`
export const RECOVER_PASSWORD_ENDPOINT = `${CORE_API_BASE}/${FRONT_OFFICE}/${AUTH}/${PASSWORDS}/forgot`
export const REFRESH_TOKEN_ENDPOINT = `${CORE_API_BASE}/${FRONT_OFFICE}/${AUTH}/refresh-token`

// endpoints to students
export const STUDENTS_GET_BY_ID_ENDPOINT = `${CORE_API_BASE}/${STUDENTS}/payment-data`

// endpoints to representatives
export const REPRESENTATIVES_GET_STUDENTS_ENDPOINT = `${CORE_API_BASE}/${REPRESENTATIVES}/${STUDENTS}`

// endpoints to place to pay
export const CREATE_SESSION_PLACE_TO_PAY_ENDPOINT = `${CORE_API_BASE}/${PLACE_TO_PAY}/create-session`
export const CHANGE_PAYMENT_METHOD_PLACE_TO_PAY_ENDPOINT = `${CORE_API_BASE}/${PLACE_TO_PAY}/add-change-payment-method`

// endpoints to notifications
export const GET_ALL_NOTIFICATIONS_ENDPOINT = `${CORE_API_BASE}/notifications`
