import { styled } from '@mui/material/styles'
import {
  Box,
  Checkbox,
  InputBase,
  InputLabel,
  Paper,
  Rating,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
} from '@mui/material'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Link } from 'react-router-dom'

export const CheckBox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.info.light,
  },
}))

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    padding: '8px',
    borderRadius: '5px',
    maxWidth: '160px',
    width: '100%',
    fontSize: '11px',
    fontWeight: 300,
    lineHeight: '13px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}))

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  '& .MuiTab-root': {
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '24px',
    fontsize: '16px!important',
    padding: '16px 60px',
    textTransform: 'capitalize',
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.info.main,
    background: theme.palette.background.paper,
    borderBottom: 0,
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.mixins.outerSpace.color,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '21px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: theme.mixins.outerSpace.color,
    borderRight: `1px solid ${theme.palette.primary.main}20`,
    '&:last-child td': {
      border: 0,
    },
  },
  border: 0,
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.mixins.greyLight.color,
  },
  border: 0,
  // hide last border
  '&:last-child td, &:last-child th': {
    // border: 0,
  },
}))

export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  background: theme.palette.background.default,
  borderBottom: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '48px',
}))

export const StyledTablePaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  background: theme.palette.background.default,
}))

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: 'white',
  overflow: 'hidden',
  '::-webkit-scrollbar': {
    width: '6px',
    background: theme.mixins.scrollBar.color,
    borderRadius: '5px',
    height: '105px',
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.info.main,
    borderRadius: '10px',
    height: 'auto',
  },
}))

export const ContainerApp = styled(Box)(() => ({
  width: '100%',
  justifyContent: 'space-between',
  maxWidth: '1700px',
  alignItems: 'start',
  flexDirection: 'row',
  display: 'flex',
  minHeight: '100vh',
}))

export const ContainerBoxWithSideBar = styled(Box)(() => ({
  width: '100%',
  marginLeft: '320px',
  minHeight: '100vh',
}))

export const InputApp = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.4),
  },
  '& .MuiInputBase-input': {
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.info.light}`,
    color: theme.palette.secondary.light,
    fontSize: '12px',
    width: '100%',
    padding: '9.375px 10px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      border: `1px solid ${theme.palette.info.light}`,
    },
    '&:disabled': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}))

export const InputLabelApp = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    color: theme.palette.secondary.main,
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 400,
  },
}))

export const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.mixins.rating.color,
  },
  '& .MuiRating-iconHover': {
    color: theme.mixins.rating.color,
  },
  '& .MuiRating-iconEmpty': {
    color: theme.mixins.rating.color,
  },
  '&.MuiRating-root.Mui-disabled': {
    opacity: '0.9',
  },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  color: theme.palette.info.light,
  lineHeight: '14px',
}))
