import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { promotionEmpty, PromotionState } from 'domain/models/promotion'
import { Details } from 'domain/entities/promotion'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: PromotionState = {
  error: null,
  isLoading: false,
  promotion: promotionEmpty,
}
export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    getPromotionGoalsStart: (state: PromotionState) => {
      state.isLoading = true
      state.error = null
    },
    getPromotionGoalsSuccess: (state: PromotionState, action: PayloadAction<Details>) => {
      state.isLoading = false
      state.promotion = action.payload
    },
    getPromotionGoalsError: (state: PromotionState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const promotionState = (state: RootState) => state.promotion
export const { getPromotionGoalsStart, getPromotionGoalsSuccess, getPromotionGoalsError } =
  promotionSlice.actions

export default promotionSlice.reducer
