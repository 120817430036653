import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { selectedChildKey } from 'presentation/constant/sessionStorageKeys'
import { Representative, Student } from 'domain/entities/representative'
import {
  PayloadUpdateStudentSelected,
  RepresentativeState,
  StudentEmpty,
} from 'domain/models/representantive'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const selectedStudentFromLocalStorage: Student =
  JSON.parse(sessionStorage.getItem(selectedChildKey) as string) || StudentEmpty

const initialState: RepresentativeState = {
  error: null,
  isLoading: false,
  students: [],
  representative: null,
  selectedStudent: selectedStudentFromLocalStorage,
  isLoadingUpdate: false,
}

export const representativeSlice = createSlice({
  name: 'representative',
  initialState,
  reducers: {
    getStudentsByRepresentativeStart: (state: RepresentativeState) => {
      state.isLoading = true
      state.error = null
    },
    getStudentsByRepresentativeSuccess: (
      state: RepresentativeState,
      action: PayloadAction<Student[]>,
    ) => {
      state.isLoading = false
      state.students = action.payload
    },
    getStudentsByRepresentativeError: (
      state: RepresentativeState,
      action: PayloadAction<string>,
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
    selectedRepresentativeChild: (state: RepresentativeState, action: PayloadAction<Student>) => {
      state.selectedStudent = action.payload
    },
    deleteRepresentativeSelectedChild: (state: RepresentativeState) => {
      state.selectedStudent = StudentEmpty
      state.students = []
    },
    updateRepresentativeStart: (state: RepresentativeState) => {
      state.error = null
      state.isLoadingUpdate = true
    },
    updateRepresentativeSuccess: (state: RepresentativeState) => {
      state.isLoadingUpdate = false
    },
    updateRepresentativeError: (state: RepresentativeState, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isLoadingUpdate = false
    },
    getGetRepresentativeStart: (state: RepresentativeState) => {
      state.isLoading = true
      state.error = null
    },
    getGetRepresentativeSuccess: (
      state: RepresentativeState,
      action: PayloadAction<Representative>,
    ) => {
      state.isLoading = false
      state.representative = action.payload
    },
    getGetRepresentativeError: (state: RepresentativeState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateStudentSelected: (state, action: PayloadAction<PayloadUpdateStudentSelected>) => {
      const newListStudents: any = [...state.students]
      const index: number = newListStudents.findIndex(
        (element: Student) => element?.studentId === action.payload.studentId,
      )
      if (index >= 0) {
        const student: Student = newListStudents[index]
        const studentFullName = `${action.payload.name} ${action.payload.surname}`
        newListStudents[index] = {
          ...student,
          studentFullName,
        }
        state.students = newListStudents
      }
    },
  },
})

export const representativeState = (state: RootState) => state.representative
export const {
  getStudentsByRepresentativeStart,
  getStudentsByRepresentativeSuccess,
  getStudentsByRepresentativeError,
  selectedRepresentativeChild,
  updateRepresentativeStart,
  updateRepresentativeSuccess,
  updateRepresentativeError,
  getGetRepresentativeStart,
  getGetRepresentativeSuccess,
  getGetRepresentativeError,
  deleteRepresentativeSelectedChild,
  updateStudentSelected,
} = representativeSlice.actions

export default representativeSlice.reducer
