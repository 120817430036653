import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PaginationEmpty } from 'domain/models/queryPagination'
import { AdditionalSavingsState } from 'domain/models/additionalSavings'
import { ReadAdditionalSavingsQueryResponse } from 'domain/entities/additionalSavings'
import { RootState } from 'presentation/redux/reducers/rootReducer'

const initialState: AdditionalSavingsState = {
  error: null,
  isLoading: false,
  additionalSavings: [],
  pagination: PaginationEmpty,
}

export const additionalSavingsSlice = createSlice({
  name: 'additionalSavings',
  initialState,
  reducers: {
    getAdditionalSavingsStart: (state: AdditionalSavingsState) => {
      state.isLoading = true
      state.error = null
    },
    getAdditionalSavingsSuccess: (
      state: AdditionalSavingsState,
      action: PayloadAction<ReadAdditionalSavingsQueryResponse>,
    ) => {
      state.isLoading = false
      state.additionalSavings = action.payload.entities
      state.pagination = action.payload.pagination
    },
    getAdditionalSavingsError: (state: AdditionalSavingsState, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const additionalSavingsState = (state: RootState) => state.additionalSavings
export const { getAdditionalSavingsStart, getAdditionalSavingsSuccess, getAdditionalSavingsError } =
  additionalSavingsSlice.actions

export default additionalSavingsSlice.reducer
