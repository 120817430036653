import { useCallback, useState } from 'react'

const useSessionStorage = (key: string) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const saveDataAtSessionStorage = useCallback(
    ({ data: newData }: any) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(newData))
        setLoading(false)
      } catch (err: any) {
        setError(err)
      }
    },
    [key, setLoading, setError],
  )

  const getSessionStorageData = useCallback(async () => {
    try {
      const element: any = await sessionStorage.getItem(key)
      setData(JSON.parse(element))
      setLoading(false)
    } catch (err: any) {
      setError(err)
    }
  }, [key, setLoading, setError])

  const removeSessionStorageData = useCallback(() => {
    try {
      sessionStorage.removeItem(key)
      setData(null)
      setLoading(false)
    } catch (err: any) {
      setError(err)
    }
  }, [key, setLoading, setError])

  return {
    getSessionStorageData,
    saveDataAtSessionStorage,
    removeSessionStorageData,
    data,
    loading,
    error,
  }
}

export default useSessionStorage
